import { getRaFSignUpEventObject } from '../../infrastructure/middleware/analytics/analyticsObjectBuilders';
import { pushEvent } from '../../utilities/dataLayerUtils';

export const successfulAuthCallback = (response, postLoginCallback) => {
  const currentUserData = JSON.parse(localStorage.getItem('user')) || {};
  let user = null;

  // CHECK IF THERE IS A USER IN LOCAL STORAGE
  if (Object.keys(currentUserData).length === 0) {
    // TAKE THE RESPONSE AS IS, IF THERE IS NO USER IN LOCAL STORAGE
    user = { ...response.data };
    // VERIFY IF THE RESPONSE HAS THE LOYALTY OPT IN FIELD AND SET DEFAULT TO FALSE
    user.loyaltyOptIn = response.data.loyaltyOptIn ?? false;
    // VERIFY IF THE POINTS BALANCE IS EXIST AND SET DEFAULT TO 0
    user.pointsBalance = response.data.pointsBalance ?? 0;
  } else {
    // SET THE USER WITH THE CURRENT USER DATA ON LOCAL STORAGE MEANING WE ARE UPDATING AFTER LOGIN
    user = { ...currentUserData };
    // CREATE ARRAY WITH ALL THE KEYS OF THE RENEW TOKEN
    const responseDataKeys = Object.keys(response.data);
    // LOOP THROUGH THE KEYS OF THE RENEW TOKEN
    responseDataKeys.forEach(key => {
      // IF THE KEY IS IN THE CURRENT USER DATA THEN UPDATE AND IF NOT ADD IT
      user[key] = response.data[key];
    });
    // VERIFY IF THE RESPONSE HAS THE LOYALTY OPT IN FIELD AND SET DEFAULT TO CURRENT USER DATA
    user.loyaltyOptIn = response.data.loyaltyOptIn ?? currentUserData.loyaltyOptIn;
    // VERIFY IF THE POINTS BALANCE EXIST AND SET DEFAULT TO CURRENT USER DATA
    user.pointsBalance = response.data.pointsBalance ?? currentUserData.pointsBalance;
  }

  //Verify if exists, if not create a default value
  //==================================================================
  if (!user.isSocialAccountLogin) user.isSocialAccountLogin = false;
  if (!user.id) user.id = null;
  if (!user.isConnectedToFacebook) user.isConnectedToFacebook = false;
  if (!user.isConnectedToGoogle) user.isConnectedToGoogle = false;
  if (!user.isConnectedToApple) user.isConnectedToApple = false;
  if (!user.hasFutureReservation) user.hasFutureReservation = null;
  if (!user.newUserCreated) user.newUserCreated = true;

  localStorage.setItem('user', JSON.stringify(user));
  if (postLoginCallback && typeof postLoginCallback === typeof Function) {
    postLoginCallback();
  }

  // Refer a Friend 25/50 test - GA Event
  if (!response.data.referralNotEligible) {
    const rafEventData = getRaFSignUpEventObject();
    pushEvent(rafEventData);
  }
  return user;
};
