// TODO - revisit this component to fix how hooks are working
/* eslint-disable react-hooks/exhaustive-deps */
import throttle from 'lodash-es/throttle';
import React, { createRef, useEffect } from 'react';
import { AffirmContainer, StyledFinancingOptionText } from './styles';

import { useHistory } from 'react-router';
import { isLocationAvailableWithAffirm } from '../../api/Utils/affirmUtils';

const refreshAffirm = throttle(() => {
  try {
    window.affirm.ui.refresh();
  } catch (e) {}
}, 500);

const FinancingOptionText = ({ productType, amount, shouldRefresh, rightAlign, resortLocation, ...restProps }) => {
  const rateContainer = createRef();
  const shouldEnableAffirm = isLocationAvailableWithAffirm(resortLocation);
  const history = useHistory();

  useEffect(() => {
    return () => {
      if (history?.action === 'POP') window.affirm?.ui?.modal?.close();
    };
  }, [history]);

  useEffect(() => {
    refreshAffirm();
  }, [shouldRefresh]);

  useEffect(() => {
    if (shouldEnableAffirm) {
      const setListeners = event => {
        const { target } = event;
        target.parentElement.querySelector('.affirm-modal-trigger').click();
      };
      // This sucks but is the only way to emulate production behavior
      rateContainer.current.addEventListener('click', setListeners);

      return () => {
        if (rateContainer && rateContainer.current) {
          rateContainer.current.removeEventListener('click', setListeners);
        }
      };
    }
  }, []);

  if (!shouldEnableAffirm) return null;

  return (
    <>
      <AffirmContainer ref={rateContainer} rightAlign={rightAlign}>
        <StyledFinancingOptionText
          m={0}
          className="affirm-as-low-as"
          data-page-type={productType}
          data-amount={amount}
          {...restProps}
        />
      </AffirmContainer>
    </>
  );
};

export default FinancingOptionText;
