import { API_URLS, getApiUrl } from '../Utils/apiURLConfiguration';
import GWRApiClient from './GWRApiClient';

const kidsQueryString = availabilityRequest => {
  if (!availabilityRequest?.kidsAges) return '';
  let kidsQs = '';
  if (typeof availabilityRequest.kidsAges === 'object') {
    availabilityRequest.kidsAges.forEach(kidAge => {
      kidsQs += '&kidsAges=' + kidAge;
    });
  } else {
    availabilityRequest.kidsAges.split(',').forEach(kidAge => {
      kidsQs += '&kidsAges=' + kidAge;
    });
  }
  return kidsQs;
};

/**
 * Concrete implementation for the RoomBooking endpoints
 *
 * @class RoomBookingApiclient
 * @extends {GWRApiClient}
 */
export default class RoomBookingApiClient extends GWRApiClient {
  constructor(version = '', token = '') {
    let apiVersion = version !== '' ? version : `v1`;
    const urlAux = getApiUrl(API_URLS.REACT_APP_ROOM_BOOKING_ENDPOINT, apiVersion);
    super(urlAux, token);
  }

  /**
   * function returns the highest suite rates by location for a given date range
   *
   * @function
   * @param {arrival: date, departure: date, numberOfAdults: string, kidsAges: Array[]} availabilityHighestRatesRequest
   * Example: {arrival: '2019-06-01', departure: '2019-06-03', numberOfAdults: 1, kidsAges: [8,6]
   * @param {string} location
   */

  async getAvailabilityHighestRates(availabilityHighestRatesRequest, location) {
    if (!location) console.error('No location passed in, location is needed to make /highest-rates call');

    const urlRequest = `${this.url}/${location}/highest-rates`;

    let request = await super.getApiCall(urlRequest, availabilityHighestRatesRequest);
    return request;
  }

  /**
   * @function
   * Sends a GET request to get response from BE if the user can use or not the Gpay and Apple Pay payments types
   * @param {suiteCode: string, property: string, arrival: date, departure: date, rateCode: string, Adults: int, children: Array[int]} paymentAvailableRequest
   * Example: { property: 'WILLVA', hasAvailabilityKey: true, HasPackages: true, HasPointsOffers:false}
   * @return available => this property will be returned and it's a boolean property
   */
  async getIsGpayApplePayAvailable(paymentAvailableRequest) {
    const urlRequest = `${this.url}/${paymentAvailableRequest.property}/digital-wallet/available`;
    delete paymentAvailableRequest.property;

    let request = await super.getApiCall(urlRequest, paymentAvailableRequest);
    return request;
  }

  /**
   * function returns the lowest rates for calendar
   *
   * @function
   * @param {Year: int, Month: int, Adults: int, KidsAges: Array[int], OfferCode: string}
   * @param {string} location
   * Example: getLowestRates({lodgeCode: GURNIL, Year: 2024, Month: 5, Adults: 1, KidsAges: [14,5,15,2], OfferCode: AMTCERT}, 'GURNIL')
   */
  async getLowestRates(lowestRatesRequest, location) {
    const queryString =
      `${this.url}/${location}/lowest-rates` +
      `?year=${lowestRatesRequest.year}` +
      `&month=${lowestRatesRequest.month}` +
      `&numberOfMonths=${lowestRatesRequest.numberOfMonths}` +
      `&adults=${lowestRatesRequest.adults}` +
      `&offerCode=${lowestRatesRequest.offerCode}` +
      `${kidsQueryString(lowestRatesRequest)}`;

    let request = await super.getApiCall(queryString);
    return request;
  }

  /**
   * function to get all the range of days of availability data by suite
   *
   * @function
   * @param {string} suiteCode
   * The suite which we are requesting
   * Example: 'WPFB'
   * @param {numberOfAdults: int, location: string, arrival: date, departure: date, offerCode: string, numNights: int, kidsAges: string} availabilityRangeBySuiteRequest
   * Example: {numberOfAdults: 2, location: 'DELLWI', arrival: '2019-06-01', departure: '2019-06-03', offerCode: 'FLEXTEST', numNights: 2, kidsAges: '0,1,2,3'}
   */
  async getAvailabilityRangeBySuite(suiteCode, availabilityRequest) {
    const queryString =
      `?offerCode=${availabilityRequest.offerCode}` +
      `&numberOfNights=${availabilityRequest.numberOfNights || availabilityRequest.numNights}` +
      `&adults=${availabilityRequest.adults || availabilityRequest.numberOfAdults}` +
      `&arrival=${availabilityRequest.arrival}` +
      `&departure=${availabilityRequest.departure}` +
      `${kidsQueryString(availabilityRequest)}`;

    const urlRequest =
      `${this.url}/${availabilityRequest.lodgeCode || availabilityRequest.location}/suites/${suiteCode}/rate-calendar` +
      queryString;

    let request = await super.getApiCall(urlRequest);
    return request;
  }

  /**
   * function returns the lowest rates for calendar
   *
   * @function
   * @param {year: number, month: number, adults: number, numberOfMonths: number, kidsAges: Array[number], offerCode: string}
   * @param {string} suiteCode
   * @param {string} location
   */
  async getLowestRatesForSuite(lowestRatesRequest, suiteCode, location) {
    const queryString =
      `${this.url}/${location}/suites/${suiteCode}/lowest-rates` +
      `?lodgeCode=${lowestRatesRequest.lodgeCode}` +
      `&year=${lowestRatesRequest.year}` +
      `&month=${lowestRatesRequest.month}` +
      `&numberOfMonths=${lowestRatesRequest.numberOfMonths}` +
      `&adults=${lowestRatesRequest.adults}` +
      `&offerCode=${lowestRatesRequest.offerCode}` +
      `${kidsQueryString(lowestRatesRequest)}`;

    return await super.getApiCall(queryString);
  }

  /**
   * function returns the lowest rates by stay without suiteCode
   *
   * @function
   * @param {lodgeCode: string, arrival: date, departure: date, adults: number, kidsAges: Array[number], location: string} lowestRatesByStayRequest
   */
  async getLowestRatesForSuiteByStayNoSuiteCode(lowestRatesByStayRequest) {
    const queryString = `${this.url}/${lowestRatesByStayRequest.lodgeCode}/lowest-rates/by-stay?${kidsQueryString(
      lowestRatesByStayRequest
    )}`;
    const { kidsAges, ...requestObject } = lowestRatesByStayRequest;

    return await super.getApiCall(queryString, requestObject);
  }

  /**
   * function returns the lowest rates by stay
   *
   * @function
   * @param {lodgeCode: string, arrival: date, departure: date, adults: number, kidsAges: Array[number], offerCode: string} lowestRatesByStayRequest
   * @param {string} suiteCode
   * @param {string} location
   */
  async getLowestRatesForSuiteByStay(lowestRatesByStayRequest, suiteCode, location) {
    const queryString = `${this.url}/${location}/suites/${suiteCode}/lowest-rates/by-stay?${kidsQueryString(
      lowestRatesByStayRequest
    )}`;
    const { kidsAges, ...requestObject } = lowestRatesByStayRequest;

    return await super.getApiCall(queryString, requestObject);
  }

  /**
   * @function
   * Sends a POST request to get an access block code
   * @param property
   */
  async getShift4i4AccessBlockTioga(property, paymentType, totalToCharge) {
    const urlRequest = `${this.url}/${property}/payment/token`;
    const payload = {
      orderDetails: {
        amount: totalToCharge, // Total paid
        paymentType: paymentType // payment type selected by the user
      }
    };
    return super.postApiCall(urlRequest, payload);
  }
}
