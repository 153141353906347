import PropTypes from 'prop-types';
import React from 'react';

import MinusIcon from '../icons/MinusThin';
import PlusIcon from '../icons/PlusThin';
import colors from '../themes/colors';
import { AnimationCircle, BUTTON_VARIATIONS, CounterWrapper, DisplayText, StyledButton } from './styles';

const propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  variation: PropTypes.string,
  width: PropTypes.number
};

const defaultProps = {
  disabled: false,
  min: 0,
  max: 999,
  variation: BUTTON_VARIATIONS.LARGE,
  iconColor: colors.pureWhite[0],
  iconDisabledColor: colors.pureWhite[0],
  buttonBG: colors.preciousStoneBlue[100],
  buttonDisabledBG: colors.preciousStoneBlue[100]
};

/** Default Counter component*/
const Counter = ({
  isNewWidgetStyleActive = false,
  fontSize,
  lineHeight,
  color,
  disabled,
  value,
  onChange,
  min,
  max,
  disableIncrease,
  variation = BUTTON_VARIATIONS.LARGE,
  width,
  buttonBG,
  buttonDisabledBG,
  iconColor,
  iconDisabledColor
}) => {
  const canBeDecreased = value > min;
  const canBeIncreased = value < max;

  return (
    <CounterWrapper isNewWidgetStyleActive={isNewWidgetStyleActive}>
      <StyledButton
        type="button"
        buttonBG={buttonBG}
        buttonDisabledBG={buttonDisabledBG}
        aria-label="decrease"
        disabled={!canBeDecreased || disabled}
        onClick={() => onChange(+value - 1)}
        variation={variation}>
        {!isNewWidgetStyleActive ? <AnimationCircle id="animation-circle" variation={variation} /> : null}
        <MinusIcon size={getSvgSize(variation)} color={!canBeDecreased || disabled ? iconDisabledColor : iconColor} />
      </StyledButton>

      <DisplayText color={color} lineHeight={lineHeight} fontSize={fontSize} variation={variation} width={width}>
        {+value}
      </DisplayText>

      <StyledButton
        type="button"
        buttonBG={buttonBG}
        buttonDisabledBG={buttonDisabledBG}
        aria-label="increase"
        disabled={!canBeIncreased || disabled || disableIncrease}
        onClick={() => onChange(+value + 1)}
        variation={variation}>
        {!isNewWidgetStyleActive ? <AnimationCircle id="animation-circle" variation={variation} /> : null}
        <PlusIcon
          size={getSvgSize(variation)}
          color={!canBeIncreased || disabled || disableIncrease ? iconDisabledColor : iconColor}
        />
      </StyledButton>
    </CounterWrapper>
  );
};

Counter.propTypes = propTypes;
Counter.defaultProps = defaultProps;

export default Counter;

function getSvgSize(variation) {
  return variation === BUTTON_VARIATIONS.LARGE ? '32px' : '24px';
}
