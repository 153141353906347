// Packages Selectors

import forEach from 'lodash-es/forEach';
import get from 'lodash-es/get';
import groupBy from 'lodash-es/groupBy';
import sortBy from 'lodash-es/sortBy';
import { getTheFirstElementOfArray, isArrayEmpty } from '../../../utilities/arraysUtils';
import { PACKAGE_TYPE, STATIC_CDN } from '../../../utilities/constants';
import { areCabanasAvailable, setUpSellTitleObj } from '../../../utilities/packagesUtils';
import { RESERVATION_TYPES } from '../ReservationDetailsEntity/reservationDetailsEntity.selectors';

/**
 * Get the sum of all selected packages an its quantities.
 * @param {Object} state Packages store
 * @return {number} Net total
 */
export const getPackagesNetTotal = state => {
  const { selectedPackages } = state;
  const packagesNetTotal = selectedPackages.reduce((acc, selectedPackage) => {
    const { total, quantity, isAdjustment } = selectedPackage;
    return !isAdjustment ? total * parseInt(quantity) + acc : acc;
  }, 0);

  return packagesNetTotal;
};

/**
 * This function returns all the packages and cabanas data and merges the result with their
 * availability date.
 * @param {Object} state Global state
 * @return {array} List of objects cotaining packages and cabanas data.
 */
export const getAllPackagesAndCabanas = state => {
  const packages = getPackages(state.packages);
  return joinAvailabilityDataToPackages(state, packages);
};

/**
 * Function to return the available cabanas
 * @param {Object} state Global state
 * @returns {array} List of the cabanas available
 */
export const getAvailableCabanas = state => {
  const packages = getAllPackagesAndCabanas(state);
  return packages.filter(pkg => pkg.isCabana);
};

/**
 * Function to get the valid package types based on cabana availability
 * @param {Object} state
 * @returns PackageTypes available based cabanas availability
 */
export const getFilteredPackageTypesBasedOnCabanaAvailability = state => {
  const upsellTiles = getUpSellTilesData(state);

  const packageTypes = upsellTiles.map(upsellTile => upsellTile.packageType);

  const availablePackages = getAllPackagesAndCabanas(state);
  const availableCabanas = availablePackages.filter(
    availablePackage =>
      availablePackage.isCabana && availablePackage.cabanasDetails && availablePackage.cabanasDetails.length
  );

  const hasAvailableCabanas = availableCabanas && availableCabanas.length;
  const isValidPackageType = packageType =>
    hasAvailableCabanas || (!hasAvailableCabanas && packageType !== PACKAGE_TYPE.cabanasAndFlexTrip);

  return packageTypes.filter(packageType => isValidPackageType(packageType));
};

/**
 * Get the sum of all selected packages taxes.
 * @param {Object} state Packages store
 * @return {number} All packages taxes
 */
export const getPackagesTaxes = state => {
  const { selectedPackages } = state;

  return selectedPackages.reduce((acc, selectedPackage) => {
    const { tax, quantity, isAdjustment } = selectedPackage;
    return !isAdjustment ? tax * parseInt(quantity) + acc : acc;
  }, 0);
};

/**
 * This function returns all the packages by type and merges the result with their
 * availability date.
 * @param {Object} state Global state
 * @param {String} type The package type
 * @return {array} List of objects cotaining packages and cabanas data.
 */
export const getPackagesAndCabanasByType = (state, type) => {
  const packages = getPackagesByType(state.packages, type);
  return joinAvailabilityDataToPackages(state, packages);
};

export const getPackagesGrossTotal = state => {
  const { selectedPackages } = state;

  if (!selectedPackages) return 0;

  const packagesGrossTotal = selectedPackages.reduce((acc, selectedPackage) => {
    const { amount, quantity, isAdjustment } = selectedPackage;
    return !isAdjustment ? amount * parseInt(quantity) + acc : acc;
  }, 0);

  return packagesGrossTotal;
};

/**
 * Get the sum of the amount of packages that should be charged at booking.
 * Those packages should be charged at the Due today and the credit card amount
 * @param {Object} state Packages store
 * @return {number} All packages amount
 */
export const getPackagesBookingGrossTotal = state => {
  const { selectedPackages } = state;

  if (!selectedPackages) return 0;

  const packagesGrossTotal = selectedPackages.reduce((acc, selectedPackage) => {
    const { amount, quantity, shouldBeChargedAtBooking, isAdjustment } = selectedPackage;
    if (!shouldBeChargedAtBooking) return acc;
    return !isAdjustment ? amount * parseInt(quantity) + acc : acc;
  }, 0);

  return packagesGrossTotal;
};

/**
 * Get the sum of all selected packages an its quantities, without those packages which will be charged at the suite price.
 * @param {Object} state Packages store
 * @return {number} Net total
 */
export const getPackagesWithoutBookChargeNetTotal = state => {
  const { selectedPackages } = state;
  const packagesNetTotal = selectedPackages.reduce((acc, selectedPackage) => {
    const { amount, quantity, tax, shouldBeChargedAtBooking, isAdjustment } = selectedPackage;
    if (shouldBeChargedAtBooking) return acc;

    const qty = parseInt(quantity);
    return !isAdjustment ? tax * qty + amount * qty + acc : acc;
  }, 0);

  return packagesNetTotal;
};

/**
 * Returns packages availability list.
 * @param {Object} State Packages Store.
 * @return {array} List of availability packages.
 */
export const getPackages = state => get(state, 'packageAvailabilityList');

/**
 * Returns the Dining packages availability list.
 * @param {Object} State Packages Store.
 * @return {array} List of availability packages.
 */
export const getDiningPackages = state => {
  const { packageAvailabilityList } = state;
  if (!packageAvailabilityList) return [];

  return packageAvailabilityList.filter(
    w => w.packageType.includes(PACKAGE_TYPE.dining) || w.packageType.includes(PACKAGE_TYPE.main)
  );
};

/**
 * Returns the Birthday packages availability list.
 * @param {Object} State Packages Store.
 * @return {array} List of availability packages.
 */
export const getPackagesByType = (state, packageType) => {
  const { packageAvailabilityList } = state;
  if (!packageAvailabilityList) return [];

  return packageAvailabilityList.filter(pck => pck.packageType?.includes(packageType));
};

/**
 * Returns the Activity and Attractions packages availability list.
 * @param {Object} State Packages Store.
 * @return {array} List of availability packages.
 */
export const getActivitiesAndAttractionsPackages = state => {
  const { packageAvailabilityList } = state;
  if (!packageAvailabilityList) return [];

  return packageAvailabilityList.filter(
    pck => pck.packageType.includes(PACKAGE_TYPE.attractions) || pck.packageType.includes(PACKAGE_TYPE.activity)
  );
};

/**
 * @function
 * Returns the Activity and Attractions packages availability list.
 * @param {object} state Package storage state
 */
export const getAttractionPassesPackages = state => {
  const { packageAvailabilityList } = state;
  if (!packageAvailabilityList) return [];

  return packageAvailabilityList.filter(pck => pck.packageType.includes(PACKAGE_TYPE.pass));
};

/**
 * Returns cabanas availability list.
 * @param {Object} State Packages Store.
 * @return {array} List of availability cabanas.
 */
export const getCabanas = state => get(state, 'cabanasAvailabilityList');

/**
 * Check if cabana is available for the selected dates and suites
 * @param {array} packagesList - available package and cabana list.
 * @return {boolean} - wether to show cabana tile or not.
 */
export const getCabanaAvailability = state => {
  const cabanasAvailabilityList = getCabanas(state);
  return areCabanasAvailable(cabanasAvailabilityList);
};

/**
 * Returns packages availability list.
 * @param {Object} State Packages Store.
 * @return {boolean} True if it is loading.
 */
export const getIsLoading = state => get(state, 'isLoading');

/**
 * Returns Late Checkout Package Declined status.
 * @param {Object} State Packages Store.
 * @return {boolean} True if declined
 */
export const getLateCheckoutDeclineStatus = state => get(state, 'isLateCheckoutDenied');

export const getLateCheckoutOfferStatus = state => get(state, 'isLateCheckoutOffered');

export const getPackagesByCode = state => {
  const packages = getPackages(state.packages);
  return packages?.reduce((packageAcc, selectedPackage) => {
    packageAcc[selectedPackage.packageCode.trim()] = {
      ...selectedPackage
    };
    return packageAcc;
  }, {});
};

export const getPackagesGroupedByTier = (state, reservationType, isNewReservation) => {
  const packages = getPackages(state);
  const isSuite = reservationType === RESERVATION_TYPES.suite;
  const isDayPass = reservationType === RESERVATION_TYPES.dayPass;
  const isSuiteConfirmation = isSuite && isNewReservation;
  const isDayPassConfirmation = isDayPass && isNewReservation;

  const { '': noTier, None, ...groupedByTier } = groupBy(
    packages,
    ({ tierDayPassConfirmation, tierDaypassReservation, tierMyReservation, tierConfirmation }) => {
      if (isDayPassConfirmation) {
        return tierDayPassConfirmation;
      } else if (isDayPass) {
        return tierDaypassReservation;
      } else if (isSuiteConfirmation) {
        return tierConfirmation;
      }
      return tierMyReservation;
    }
  );

  let result = {};

  forEach(groupedByTier, (groupedPackage, key) => {
    result[key] = sortBy(groupedPackage, gwPackage => parseInt(gwPackage.orderMyReservation));
  });

  return result;
};

export const getPlanPackages = state => get(state, 'planPackages');

/**
 * Returns packages availability list.
 * @param {Object} State Packages Store.
 * @return {boolean} True if request failed
 */
export const getRequestFailed = state => get(state, 'requestFailed');

/**
 * Returns selected packages list.
 * @param {Object} State Packages Store.
 * @return {array} List of availability packages.
 */
export const getSelectedPackages = state => get(state, 'selectedPackages');

/**
 * This function returns the Upsell tiles data based on the packageTablesJson and cabanasJson
 * content files which comes from AEM
 * @returns {Array of Objects}
 */
export const getUpSellTilesData = state => {
  const packages = getPlanPackages(state.packages);
  const allPackages = getPackages(state.packages);

  return setUpSellTitleObj(allPackages, packages);
};

/**
 * This function properly join the availability data to the packages and cabanas
 * returning a full detailed list of packages and cabanas.
 * @param {Object} state Global state.
 * @param {Object} packagesAndCabanas List of packages and cabanas whitout the
 * availability information.
 * @return {array} List of packages and cabanas fully detailed with their
 * availability information.
 */
const joinAvailabilityDataToPackages = (state, packagesAndCabanas) => {
  const availabilityPackages = getPackages(state.packages);
  const availabilityCabanas = getCabanas(state.packages);

  return (
    packagesAndCabanas.length > 0 &&
    packagesAndCabanas?.map(pkg => {
      const cabanaAvailable =
        availabilityCabanas?.length > 0 &&
        availabilityCabanas?.filter(avCabana => avCabana.itemCode === pkg.packageCode && !avCabana.errorCode);

      if (cabanaAvailable && cabanaAvailable?.length > 0) {
        return {
          ...pkg,
          isCabana: true,
          cabanasDetails: cabanaAvailable?.map(cabana => {
            return {
              amount: get(cabana, 'roomBaseRate'),
              tax: get(cabana, 'taxesAndFees'),
              total: get(cabana, 'roomRateTotal'),
              errorCode: get(cabana, 'errorCode'),
              statusText: get(cabana, 'statusText'),
              itemAvailable: get(cabana, 'itemAvailable'),
              date: get(cabana, 'date')
            };
          })
        };
      }
      const packageAvailable = availabilityPackages?.find(avPackage => avPackage.packageCode === pkg.packageCode);
      return {
        ...pkg,
        subTitle: get(packageAvailable, 'subTitle', ''),
        amount: get(packageAvailable, 'amount'),
        tax: get(packageAvailable, 'tax'),
        total: get(packageAvailable, 'total')
      };
    })
  );
};

/**
 * Function to return the available cabanas
 * @param {Object} state Global state
 * @returns {array} List of the cabanas available
 */
export const getAvailableCabanasV3 = state => {
  const cabanas = state.cabanasAvailabilityList;
  return cabanas;
};

export const getAddedCabanasv3 = ({ packages, addApackage }) => {
  const addedPkgs = addApackage.addedPackages;
  const cabanasCodes = packages?.cabanasAvailabilityList?.map(pkg => pkg.rateCode);
  const filteredCabanasAdded = [];
  for (const pkg in addedPkgs) {
    if (cabanasCodes?.includes(addedPkgs[pkg].item.rate)) {
      filteredCabanasAdded?.push(addedPkgs[pkg]);
    }
  }

  return filteredCabanasAdded;
};

/**
 * Returns the Early Waterpark Access package if available.
 * @param {Object} State Packages Store.
 * @return {Object} Early Waterpark Access package.
 */
export const getEarlyWaterparkAccess = state => {
  const { planPackages } = state;
  if (!planPackages) return [];
  const familyPackage = planPackages?.filter(pck => pck.packageTabName === 'Family');
  const earlyWaterparkAccess =
    familyPackage[0]?.packageList && familyPackage[0]?.packageList?.find(pck => pck.title === 'Early Waterpark Access');

  if (earlyWaterparkAccess) {
    const fullEarlyWaterparkAccess = {
      ...earlyWaterparkAccess,
      image: {
        url: STATIC_CDN + 'early-access-wp.png',
        alt: STATIC_CDN + 'early-access-wp.png'
      },
      cardTitle: 'Add Early Water Park Access',
      description: 'This one-time cost gets your group 9:00 am early access to our water park each day!'
    };
    return fullEarlyWaterparkAccess;
  }

  return false;
};

export const getBundlePackage = state => {
  const BUNDLE_TABLE_NAME = 'Bundle';
  const bundlePkg = state.planPackages?.find(packageItem => packageItem.packageTableName === BUNDLE_TABLE_NAME);
  if (bundlePkg && !isArrayEmpty(bundlePkg?.packageList ?? [])) {
    return getTheFirstElementOfArray(bundlePkg.packageList);
  } else {
    return null;
  }
};

/**
 * Returns the selected packages location.
 * @param {Object} State Packages Store.
 * @return {array} List of availability packages.
 */
export const getSelectedPkgsLocationCode = state => state.selectedPkgsLocationCode;

/**
 * Rreturn fing available package by code
 * @param {*} state
 * @param {*} packageCode
 * @returns
 */
export const getPackageByPackageCode = (packages, packageCode) => {
  const availablePackages = getPackages(packages);
  return availablePackages.find(pkg => pkg.packageCode === packageCode);
};

export const getItemQuantityState = state => state.itemQuantity;
