// Packages action types

const packagesActionTypes = {
  GET_PACKAGES_AVAILABILITY_INFO_SUCCESS: 'GET_PACKAGES_AVAILABILITY_INFO_SUCCESS',
  GET_PACKAGES_AVAILABILITY_INFO_LOADING: 'GET_PACKAGES_AVAILABILITY_INFO_LOADING',
  GET_PACKAGES_AVAILABILITY_INFO_ERROR: 'GET_PACKAGE_AVAILABILITY_INFO_ERROR__FIX_DUPLICATE',
  CLEAR_PACKAGES_SELECTED: 'CLEAR_PACKAGES_SELECTED',
  UPSERT_PACKAGES: 'UPSERT_PACKAGES',
  DELETE_PACKAGES: 'DELETE_PACKAGES',
  OFFERED_PACKAGES_LCO: 'OFFERED_PACKAGES_LCO',
  DECLINE_PACKAGES_LCO: 'DECLINE_PACKAGES_LCO',
  CLEAR_PACKAGES_BY_CODES: 'CLEAR_PACKAGES_BY_CODES__FIX_DUPLICATE',
  CLEAR_PACKAGES_PLAN: 'CLEAR_PACKAGES_PLAN',
  GET_PACKAGES_CABANAS_AVAILABILITY_SUCCESS: 'GET_PACKAGES_CABANAS_AVAILABILITY_SUCCESS',
  GET_PACKAGES_CABANAS_AVAILABILITY_LOADING: 'GET_PACKAGES_CABANAS_AVAILABILITY_LOADING',
  GET_PACKAGES_CABANAS_AVAILABILITY_ERROR: 'GET_PACKAGES_CABANAS_AVAILABILITY_ERROR',
  SET_PACKAGES_PLAN_INFO_SUCCESS: 'SET_PLAN_PACKAGES_INFO_SUCCESS__FIX_DUPLICATE',
  GTM_PACKAGES_IMPRESSIONS: 'GTM_PACKAGES_IMPRESSIONS',
  GET_ADD_ONS_AVAILABILITY_INFO_SUCCESS: 'GET_ADD_ONS_AVAILABILITY_INFO_SUCCESS',
  GET_ADD_ONS_AVAILABILITY_INFO_LOADING: 'GET_ADD_ONS_AVAILABILITY_INFO_LOADING',
  GET_ADD_ONS_AVAILABILITY_INFO_ERROR: 'GET_ADD_ONS_AVAILABILITY_INFO_ERROR'
};

export default packagesActionTypes;
