// Suite Selectors

import { PERSONALIZATION_PACKAGE_RULES } from '../../../utilities/constants';
import { getPackagesBookingGrossTotal } from '../Packages/packages.selectors';

/**
 * Get selected suite
 * @param {Object} state
 * @returns {Object} Represent selected suite.
 */
export const getSelectedSuite = state => {
  return state.selectedSuite;
};

/**
 * Get suite net total.
 * @param {Object} state Suite store
 * @return {number} Suite gross total plus taxes
 */
export const getSuiteNetTotal = state => {
  const suiteGrossTotal = getSuiteGrossTotal(state);
  const suiteTaxesAndFees = getSuiteTaxes(state);
  return suiteGrossTotal + suiteTaxesAndFees;
};

/**
 * Get suite title
 * @param {Object} state Suite store
 * @return {string} Suite title
 */
export const getSuiteTitle = state => {
  const selectedSuite = getSelectedSuite(state);
  return selectedSuite ? selectedSuite.title : '';
};

/**
 * Get suite headline
 * @param {Object} state Suite store
 * @return {string} Suite headline
 */
export const getSuiteHeadline = state => {
  const selectedSuite = getSelectedSuite(state);
  return selectedSuite ? selectedSuite.headline : '';
};

/**
 * Get the Sustainability fee all selected packages an its quantities.
 * @param {Object} state Packages store
 * @return {number} Sustainability Fee
 */
export const getSustainabilityFee = state => {
  const { suiteDetailedAvailability } = state;
  return suiteDetailedAvailability && suiteDetailedAvailability.length > 0
    ? suiteDetailedAvailability[0].sustainabilityFee
    : 0;
};

/**
 * Get the Resort fee all selected packages an its quantities.
 * @param {Object} state Packages store
 * @return {number} Resort Fee
 */
export const getResortFee = state => {
  const { suiteDetailedAvailability } = state;
  return suiteDetailedAvailability && suiteDetailedAvailability.length > 0 ? suiteDetailedAvailability[0].resortFee : 0;
};

/**
 * Get the Parking fee all selected packages an its quantities.
 * @param {Object} state Packages store
 * @return {number} Parking Fee
 */
export const getParkingFee = state => {
  const { suiteDetailedAvailability } = state;
  return suiteDetailedAvailability && suiteDetailedAvailability.length > 0
    ? suiteDetailedAvailability[0].parkingFee
    : 0;
};

/**
 * Get suite promotional savings.
 * @param {Object} state Suite store
 * @return {number} Suite promotional savings
 */
export const getPromotionalSavings = state => {
  return Math.abs(getSuiteBaseRate(state) - getSuiteGrossTotal(state));
};

/**
 * Get suite taxes for the current stay.
 * @param {Object} state Suite store
 * @return {number} Suite taxes for the stay
 */
export const getSuiteTaxes = state => {
  const { suiteDetailedAvailability } = state;
  return suiteDetailedAvailability && suiteDetailedAvailability.length > 0 ? suiteDetailedAvailability[0].taxes : 0;
};

/**
 * Get suite gross total for the stay.
 * @param {Object} state Suite store
 * @return {number} Suite gross total
 */
export const getSuiteGrossTotal = state => {
  const { suiteDetailedAvailability } = state;

  return suiteDetailedAvailability && suiteDetailedAvailability.length > 0
    ? suiteDetailedAvailability[0].totalNightlyRate
    : 0;
};

/**
 * Get suite base rate for the stay.
 * @param {Object} state Suite store
 * @return {number} Suite base rate
 */
export const getSuiteBaseRate = state => {
  const { selectedSuite, suiteDetailedAvailability } = state;
  const numOfNights = suiteDetailedAvailability ? suiteDetailedAvailability.length : 0;
  return Number(selectedSuite?.highestRate) * numOfNights;
};

export const getSuiteAndPackageTotalCharge = state => {
  const suiteNetTotal = getSuiteNetTotal(state.suite);
  const packageSuiteNetTotal = getPackagesBookingGrossTotal(state.packages);
  return suiteNetTotal + packageSuiteNetTotal;
};

export const getSuiteType = state => {
  const selectedSuite = getSelectedSuite(state);
  return selectedSuite ? selectedSuite.suiteType : '';
};

/**
 * Returns the Interactions package personalization data from the detail availability reponse
 * @param {Object} state
 */
export const getPackageInteractionsData = state => {
  const interactions =
    state.personalizationResponses &&
    state.personalizationResponses.filter(pr => pr.rule === PERSONALIZATION_PACKAGE_RULES.interactions);

  return interactions && interactions.length > 0 && interactions[0].data;
};

export const hasPlanPackages = state => {
  return state.suite.responseHasPackages;
};

export const getSuitIsLoading = state => {
  return state.suite.getAvailabilityLoading;
};

export const getRoomAdjustmentTax = state => {
  return state?.suite?.suiteDetailedAvailability[0]?.roomAdjustmentTax ?? 0;
};

export const getSuiteLCO = state => {
  return state.suiteLcoPackage;
};

export const getDetailedAvailabilitySuccessStatus = state => {
  return state.suiteDetailsFetchSuccess;
};

export const getPlanPackageInfoLoading = state => {
  return state.getPlanPackageInfoLoading;
};

export const getRateType = state => {
  return state?.selectedSuite?.rateType || '';
};

export const getTaxesAndFeesDetailAvailability = state => {
  return state.taxesAndFeesDetailAvailability;
};

export const getFlexPackage = state => state.flexPackage;

export const getFlexTripAllowed = state => state.flexTripAllowed;
