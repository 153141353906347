import { ASSETS_LOCATIONS, getAssetsUrl } from '../../../utilities/constants';

export const PAYMENT_FORM_IMAGES = {
  VISA_CURVED: 'visa-curved-32px.png',
  MASTERCARD_CURVED: 'mastercard-curved-32px.png',
  AMERICAN_EXPRESS_CURVED: 'american-express-curved-32px.png',
  DISCOVER_CURVED: 'discover-curved-32px.png',
  CARD_BACK_SECURITY_CVV: 'card-back-security-cvv.png',
  UI_ICON_CREDITCARD: 'ui-icon-creditcard.png',
  AFFIRM_LOGO: 'affirmLogo.png',
  APPLE_PAY_LOGO: 'applepaylogo.png',
  GOOGLE_PAY_LOGO: 'googlepaylogo.png',
  PAYMENT_FORM_CARAT: 'payment-form-carat.svg',
  AFFIRM_LOGO_WHITE: 'https://cdn-assets.affirm.com/images/white_logo-solid_bg.svg',
  VISA: 'visa.svg',
  MASTER_CARD: 'mastercard.svg',
  AMERICAN_EXPRESS: 'amex.svg',
  DISCOVER: 'discover.svg',
  MONEY_ICON: getAssetsUrl(ASSETS_LOCATIONS.SVG, 'money.svg'),
  GOOGLE_PAY: getAssetsUrl(ASSETS_LOCATIONS.CONTENT, 'png/googlePay.png'),
  APPLE_PAY: getAssetsUrl(ASSETS_LOCATIONS.CONTENT, 'png/applePay.png'),
  PAYPAL: getAssetsUrl(ASSETS_LOCATIONS.CONTENT, 'png/paypal.png'),
  CREDIT_CARD: getAssetsUrl(ASSETS_LOCATIONS.CONTENT, 'png/creditCard.png'),
  AFFIRM: getAssetsUrl(ASSETS_LOCATIONS.CONTENT, 'png/affirm.png')
};
