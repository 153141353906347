import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animation, Box, Text } from '../../../../../ui-kit';
import * as COPY from '../../utilities/copy';
import { SUMMARY_IMAGES } from '../assets/images';
import {
  CartBubble,
  CartBubleTopArrow,
  CartLogoContainer,
  CartLogoCounter,
  CartLogoImg,
  CartLogoWithAnimation,
  CartMobileSubtotalTopArrow
} from '../styles';

const propTypes = {
  packagesCount: PropTypes.number,
  toggleOpen: PropTypes.func
};

const defaultProps = {
  packagesCount: 0,
  toggleOpen: () => {}
};

const CartLogo = ({ packagesCount = 0, toggleOpen, overlayVisible, summaryTotal, isMobile, isTablet }) => {
  const [showAnimation, setShowAnimation] = useState(false);
  const [showArrow, setShowArrow] = useState(true);
  const currentPackageCount = useRef(packagesCount);
  const headerElement = document.querySelector('.header-container');
  const [arrowPosition, setArrowPosition] = useState(headerElement?.offsetHeight / 2 + 8);

  const path = window.location.pathname;
  const isActivitiesOrDining = path.includes('plan') && (path.includes('activities') || path.includes('dining'));

  const appYPosition = useCallback(() => {
    const headerSize = headerElement
      ? headerElement?.clientHeight - (headerElement?.clientHeight - 45)
      : isMobile
      ? 58
      : 68;
    setArrowPosition(headerElement?.offsetHeight / 2 + 8);
    const appScrollYPosition = window.scrollY;

    if (appScrollYPosition > headerSize) setShowArrow(false);
    if (appScrollYPosition <= headerSize) setShowArrow(true);
  }, [isMobile, headerElement]);

  useEffect(() => {
    window.addEventListener('scroll', e => appYPosition(e));
    return () => {
      window.removeEventListener('scroll', e => appYPosition(e));
    };
  }, [appYPosition]);

  useEffect(() => {
    if (packagesCount > currentPackageCount.current) {
      setShowAnimation(true);
      currentPackageCount.current = packagesCount;
      const closeAnimation = setTimeout(() => {
        setShowAnimation(false);
      }, 1500);

      return () => {
        clearTimeout(closeAnimation);
      };
    } else if (packagesCount !== currentPackageCount.current) {
      currentPackageCount.current = packagesCount;
    }
  }, [packagesCount]);

  return (
    <CartLogoContainer textAlign="center" onClick={toggleOpen} overlayVisible={overlayVisible}>
      <CartLogoImg src={SUMMARY_IMAGES.CART_LOGO} alt="Cart" />
      {packagesCount > 0 ? (
        <CartLogoWithAnimation showAnimation={showAnimation}>
          {showAnimation ? (
            <Box>
              <Animation src={SUMMARY_IMAGES.COUNTER_CONFETTI} name="CounterConfetti" autoplay={true} loop={true} />
            </Box>
          ) : null}
          <CartLogoCounter>
            <Text fontSize={9} fontWeight="bold" lineHeight={14} color="pureWhite.0">
              {packagesCount}
            </Text>
          </CartLogoCounter>
          {isActivitiesOrDining && showArrow && (isMobile || isTablet) ? (
            <CartMobileSubtotalTopArrow arrowPosition={arrowPosition} />
          ) : null}
        </CartLogoWithAnimation>
      ) : null}
      {!overlayVisible && isActivitiesOrDining && !isMobile && !isTablet ? (
        <CartBubble>
          <CartBubleTopArrow />
          <Text as="label">
            {COPY.SUBTOTAL}: <Text as="span">${Number(summaryTotal).toFixed()}</Text>
          </Text>
        </CartBubble>
      ) : null}
    </CartLogoContainer>
  );
};

CartLogo.propTypes = propTypes;
CartLogo.defaultProps = defaultProps;

export default CartLogo;
