import { isLocationAvailableWithAffirm } from '../../../api/Utils/affirmUtils';
import { isPayPalEnabled } from '../../../api/Utils/paypalUtils';

export const paymentMethodsTypes = {
  CREDIT_CARD: 'credit-card',
  CREDIT_CARD_ENABLED: 'creditEnabled',
  AFFIRM: 'affirm',
  AFFIRM_ENABLED: 'affirmEnabled',
  PAYPAL: 'paypal',
  PAYPAL_ENABLED: 'payPalEnabled',
  APPLE_PAY: 'applePayEnabled',
  GOOGLE_PAY: 'googlePayEnabled'
};

export const getShouldDisplayGooglePay = (paymentMethodsAllowed, isSuiteBookingFlow, gpayApplePayIsActive) =>
  paymentMethodsAllowed &&
  paymentMethodsAllowed[paymentMethodsTypes.GOOGLE_PAY] &&
  isSuiteBookingFlow &&
  gpayApplePayIsActive;

export const getShouldDisplayCreditCard = paymentMethodsAllowed =>
  paymentMethodsAllowed && paymentMethodsAllowed[paymentMethodsTypes.CREDIT_CARD_ENABLED];

export const getShouldDisplayAffirm = (paymentMethodsAllowed, isSuiteBookingFlow, resortLocation) =>
  Boolean(
    paymentMethodsAllowed &&
      paymentMethodsAllowed[paymentMethodsTypes.AFFIRM_ENABLED] &&
      isLocationAvailableWithAffirm(resortLocation) &&
      isSuiteBookingFlow
  );

export const getShouldDisplayPaypal = (paymentMethodsAllowed, resortLocation) =>
  Boolean(
    paymentMethodsAllowed &&
      paymentMethodsAllowed[paymentMethodsTypes.PAYPAL_ENABLED] &&
      isPayPalEnabled(process.env.REACT_APP_PAYPAL_ENABLED_AT, resortLocation)
  );

export const getShouldDisplayApplePay = (paymentMethodsAllowed, isSuiteBookingFlow, gpayApplePayIsActive) =>
  Boolean(
    paymentMethodsAllowed &&
      paymentMethodsAllowed[paymentMethodsTypes.APPLE_PAY] &&
      isSuiteBookingFlow &&
      window.ApplePaySession !== undefined &&
      gpayApplePayIsActive
  );

export const getHasAvailabilityKey = selectedSuite =>
  Boolean(
    selectedSuite?.availabilityKey && selectedSuite?.availabilityKey !== '' && selectedSuite?.availabilityKey !== null
  );

export const getHasPointsOffers = (pointsBalance, activeOffers) =>
  (pointsBalance && pointsBalance !== 0) || activeOffers?.length !== 0 ? true : false;

export const getTotalToCharge = (dueTodayAmount, summaryTotal, isDaypassBookingFlow) => {
  const value = isDaypassBookingFlow ? summaryTotal : dueTodayAmount;

  return value && value > 0 ? parseFloat(value).toFixed(2) : '0.00';
};

export const handleTotalWithPackagesForDayPass = ({ selectedPackages, summaryTotal }) => {
  if (selectedPackages.length < 1) {
    return summaryTotal.toFixed(2);
  }
  let newTotal = summaryTotal;
  selectedPackages &&
    selectedPackages.forEach(item => {
      newTotal += item.amount;
    });
  return newTotal.toFixed(2);
};

export const getValidationErrors = errorObj => {
  return [
    ...Object.keys(errorObj.validationErrors).reduce((accumulator, validationError) => {
      const messages = errorObj.validationErrors[validationError];
      return [...accumulator, ...messages];
    }, []),
    ...(errorObj.details ? errorObj.detail : []),
    ...((errorObj &&
      errorObj.err &&
      errorObj.err.response &&
      errorObj.err.response.data &&
      errorObj.err.response.data.detail && [errorObj.err.response.data.detail]) ||
      [])
  ];
};

export const optionsCreditCardFirst = [
  paymentMethodsTypes.CREDIT_CARD,
  paymentMethodsTypes.APPLE_PAY,
  paymentMethodsTypes.GOOGLE_PAY,
  paymentMethodsTypes.AFFIRM,
  paymentMethodsTypes.PAYPAL
];

export const optionsApplePayFirst = [
  paymentMethodsTypes.APPLE_PAY,
  paymentMethodsTypes.GOOGLE_PAY,
  paymentMethodsTypes.CREDIT_CARD,
  paymentMethodsTypes.AFFIRM,
  paymentMethodsTypes.PAYPAL
];
