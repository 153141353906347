// Plans Actions

import get from 'lodash-es/get';
import AvailabilityApiClient from '../../../api/clients/AvailabilityApiClient';
import RoomBookingApiClient from '../../../api/clients/RoomBookingApiClient';
import { isBBAR } from '../../../api/Utils/BestAvailableRate';
import { GwDatesWrapper } from '../../../components/_internal_date_/gwDatesWrapper';
import { rateCalendarGA } from '../../../infrastructure/middleware/analytics/analyticsObjectBuilders';
import RecentSearchesRepository from '../../../repositories/RecentSearchesRepository';
import { getTestValueAB, TESTS_DICTIONARY } from '../../../utilities/ABTesting';
import {
  EVENT_CATEGORIES,
  trackVariationHEventError,
  trackVariationJEventError
} from '../../../utilities/applicationInsights';
import { getTheFirstElementOfArray } from '../../../utilities/arraysUtils';
import { getNormalizedCode } from '../../../utilities/availabilityUtils';
import { DATE_FORMATS, GTM_EVENT, SUITE_TYPES } from '../../../utilities/constants';
import { pushEvent } from '../../../utilities/dataLayerUtils';
import { getNumberOfBillableGuests } from '../../../utilities/guestsUtils';
import { getResortLocation } from '../CurrentLodge/currentLodge.selectors';
import { gaTrackingPlanSearchErrorCode } from '../utilities/util';
import plansActionTypes from './plans.types';

const transformRestrictions = data => {
  const dates = Object.keys(data);
  return { restrictions: dates.reduce((items, current) => ({ ...items, [current]: data[current].restrictions }), {}) };
};

export const setLowestRatesByStayNoSuiteCodeLastDates = (dtStart, dtEnd) => dispatch => {
  dispatch({
    type: plansActionTypes.LAST_PLANS_CALENDAR_RATES_BY_STAY_SELECTION,
    lastCalendarRatesDateSelection: {
      start: dtStart,
      end: dtEnd
    }
  });
};

export const getLowestRatesByStayNoSuiteCode = requestObject => async dispatch => {
  const apiClient = new RoomBookingApiClient('v1.0');

  try {
    const lowestRatesByStayResponse = await apiClient.getLowestRatesForSuiteByStayNoSuiteCode(
      requestObject,
      requestObject.lodgeCode
    );
    const { daily, total } = lowestRatesByStayResponse?.data;
    dispatch({
      type: plansActionTypes.OPEN_PLANS_CALENDAR_RATES_BY_STAY,
      lowestRatesByStayList: daily,
      lowestRatesByStayListTotal: total
    });
    dispatch({
      type: plansActionTypes.LAST_PLANS_CALENDAR_RATES_BY_STAY_SELECTION,
      lastCalendarRatesDateSelection: {
        start: requestObject.arrival,
        end: requestObject.departure
      }
    });
  } catch (err) {
    console.log(err);
  }
};

export const cleanLowestRatesByStayNoSuiteCode = () => dispatch => {
  dispatch({
    type: plansActionTypes.CLEAN_PLANS_CALENDAR_RATES_BY_STAY
  });
};

const getLowestRatesByStay = async (lowestRatesData, requestObject, suiteCode) => {
  const apiClient = new RoomBookingApiClient('v1.0');
  const shouldFetchRatesByStay =
    requestObject.arrival && requestObject.departure && lowestRatesData[requestObject.arrival];
  if (!shouldFetchRatesByStay) return {};

  try {
    const lowestRatesByStayResponse = await apiClient.getLowestRatesForSuiteByStay(
      requestObject,
      suiteCode,
      requestObject.lodgeCode
    );
    return lowestRatesByStayResponse?.data;
  } catch (err) {
    return {};
  }
};

const getCalendarRates = async (requestObject, suiteCode, dispatch) => {
  const apiClient = new RoomBookingApiClient('v1.0');

  const { year, month, numberOfMonths, ...lowestRatesByStayRequestObject } = requestObject;

  const lowestRatesResponse = await apiClient.getLowestRatesForSuite(requestObject, suiteCode, requestObject.lodgeCode);
  const { daily } = await getLowestRatesByStay(lowestRatesResponse?.data, lowestRatesByStayRequestObject, suiteCode);
  dispatch({
    type: plansActionTypes.OPEN_PLANS_CALENDAR_RATES_BY_STAY,
    lowestRatesByStayList: daily
  });
  return { ...(lowestRatesResponse?.data ?? {}), ...daily };
};

export const setIsErrorModalAlreadyClosed = value => dispatch => {
  dispatch({
    type: plansActionTypes.SET_PLANS_IS_ERROR_MODAL_ALREADY_CLOSED,
    value: value
  });
};
export const setIsCartOpenClose = value => dispatch => {
  dispatch({
    type: plansActionTypes.SET_PLANS_IS_CART_OPEN_CLOSE,
    value
  });
};
export const setIsCartOpenCloseClean = () => dispatch => {
  dispatch({
    type: plansActionTypes.SET_PLANS_IS_CART_OPEN_CLOSE_CLEAN
  });
};
export const setCartReturnedPageLinkClicked = pageToReturn => dispatch => {
  dispatch({
    type: plansActionTypes.SET_PLANS_CART_RETURNED_PAGE_LINK_CLICKED,
    pageToReturn: pageToReturn
  });
};
export const setCartReturnedPageLinkClickedClean = () => dispatch => {
  dispatch({
    type: plansActionTypes.SET_PLANS_CART_RETURNED_PAGE_LINK_CLICKED_CLEAN
  });
};
// Perform the request search by Phone and Cc to API endpoint
export const getAvailability = (requestObject, shouldIgnoreOfferCodeError = false) => (dispatch, getState) => {
  dispatch({
    type: plansActionTypes.GET_PLANS_AVAILABILITY_INFO_LOADING,
    shouldIgnoreOfferCodeError: shouldIgnoreOfferCodeError,
    availabilityReqIsLoading: true,
    offerCodeError: undefined,
    availabilityList: []
  });

  // This way is the one we are going to use for get the Authorization from the AuthClient
  // Call General Availability endpoint to retrieve info on available suites
  let apiClient = new AvailabilityApiClient('v2.3');

  const request = apiClient.getAvailability(requestObject);

  return request.then(
    response => {
      const existsValidSuite = response && response.data && response.data.suites && response.data.suites.length > 0;

      const errorCode = get(response, 'data.errorCode', undefined);
      const rateCode = existsValidSuite && response.data.suites[0].rateCode;
      const isQualifyIdRequired = get(response, 'data.isQualifyIdRequired', false);
      const promoDescription = get(response, 'data.planDescription', '');
      const hasErrorCode = errorCode && requestObject.offerCode && isBBAR(rateCode);
      const personalizationRules = response.data.pr;
      const personalizationResponses = get(response, 'data.rr', []);
      const offerType = get(response, 'data.offerType', '');
      const lcoPackages = get(response, 'data.lco', []);
      const upsellPackagesContent = get(response, 'data.upsellPackagesContent', []);
      const availabilityList = existsValidSuite ? response.data.suites : [{}];
      const isTwoRateTypesSuites =
        existsValidSuite && response?.data?.suites?.some(suite => suite.rateOptions?.length > 1);

      //This code below until the end tag is for create a tracking for version J and H issues
      //======================================================================================
      const optimizelyDisplayResortFeeWithTotalInSuiteCardJ = getTestValueAB(
        TESTS_DICTIONARY.optimizelyDisplayResortFeeWithTotalInSuiteCardJ,
        false
      );
      const optimizelyDisplayResortFeeWithTotalInSuiteCardH = getTestValueAB(
        TESTS_DICTIONARY.optimizelyDisplayResortFeeWithTotalInSuiteCardH,
        false
      );
      const { isCaliforniaResident, latestVersionJEventErrorObj, latestVersionHEventErrorObj } = getState().plans;
      const { resortLocation } = getState().currentLodge;
      const { pricePerWaterPass } = getState().lodgeConfig;
      const { locationList } = getState().lodgeLocations;
      const { checkinDateSelection, checkoutDateSelection } = getState().dates;
      const { adultsCount, kidsCount, kidsAges } = getState().guests;
      const { code } = getState().offer;
      const lodgeLocationName = locationList[resortLocation]?.url;
      //Start version H tracking code
      //=============================
      if (optimizelyDisplayResortFeeWithTotalInSuiteCardH) {
        const numberOfBillableGuests = getNumberOfBillableGuests(adultsCount, kidsAges);
        const numberOfNights = GwDatesWrapper.diff(checkoutDateSelection, checkinDateSelection, 'days');
        //Savings percentage calculation START
        //=========================================
        let savingsLessThanFiveUpperToFithty = [];
        availabilityList?.forEach(suite => {
          const validGuests = numberOfBillableGuests <= 4 ? 4 : numberOfBillableGuests;
          const suiteOnlyRate = suite?.rateOptions?.find(rate => rate.rateType === 'ROOM_ONLY')?.averageNightlyBaseRate;
          const suiteWithWaterParkRate = suite?.rateOptions?.find(rate => rate.rateType === 'WITH_WP')
            ?.averageNightlyBaseRate;
          const DPP_total = pricePerWaterPass * validGuests * (numberOfNights + 1);
          const DPP_new = (suiteWithWaterParkRate - suiteOnlyRate) * numberOfNights;
          const savings = DPP_total - DPP_new;
          const percentage = ((savings / DPP_total) * 100).toFixed();
          if (percentage < 5 || percentage > 50) {
            savingsLessThanFiveUpperToFithty.push({
              suiteName: suite.title,
              percentage: percentage + '%'
            });
          }
        });
        //Savings percentage calculation END
        //===================================
        if (savingsLessThanFiveUpperToFithty.length > 0) {
          const variationHObj = {
            lodge: lodgeLocationName,
            dates:
              'Arrival:' +
              GwDatesWrapper.format(checkinDateSelection, DATE_FORMATS.default) +
              ' Departure:' +
              GwDatesWrapper.format(checkoutDateSelection, DATE_FORMATS.default),
            promoCode: code,
            partySize: adultsCount + kidsCount,
            suiteStyle: 'version H',
            savings: savingsLessThanFiveUpperToFithty
          };
          if (JSON.stringify(latestVersionHEventErrorObj) !== JSON.stringify(variationHObj)) {
            trackVariationHEventError(EVENT_CATEGORIES.OnVersionHEventError, variationHObj);
            dispatch({
              type: plansActionTypes.SET_PLANS_LATEST_H_VERSION_EVENT_ERROR,
              variationHObj
            });
          }
        }
      }
      //End version H tracking code
      //=============================
      //Start version J tracking code
      //===============================
      if (optimizelyDisplayResortFeeWithTotalInSuiteCardJ) {
        const isJVersionAndIsTwoRatesTypesPresent =
          optimizelyDisplayResortFeeWithTotalInSuiteCardJ && isTwoRateTypesSuites;
        const isJVersionAndIsCaliforniaResident =
          optimizelyDisplayResortFeeWithTotalInSuiteCardJ && isCaliforniaResident;
        const isJVersionAndIsTwoRatesTypesPresentAndNonTwoRatesPresent =
          isJVersionAndIsTwoRatesTypesPresent &&
          availabilityList?.some(
            suite => suite.availabilityKey && (suite.rateOptions === null || suite.rateOptions?.length <= 1)
          );
        if (
          availabilityList?.length > 0 &&
          (!isJVersionAndIsTwoRatesTypesPresent ||
            isJVersionAndIsCaliforniaResident ||
            isJVersionAndIsTwoRatesTypesPresentAndNonTwoRatesPresent)
        ) {
          const variationJObj = {
            lodge: lodgeLocationName,
            dates:
              'Arrival:' +
              GwDatesWrapper.format(checkinDateSelection, DATE_FORMATS.default) +
              ' Departure:' +
              GwDatesWrapper.format(checkoutDateSelection, DATE_FORMATS.default),
            promoCode: code,
            partySize: adultsCount + kidsCount,
            californiaResidentFlag: isCaliforniaResident,
            rateCodes: availabilityList
              ?.filter(
                suite =>
                  suite.availabilityKey !== null &&
                  (isCaliforniaResident
                    ? suite.rateOptions?.length <= 1 || suite.rateOptions === null || suite.rateOptions.length > 1
                    : suite.rateOptions?.length <= 1 || suite.rateOptions === null)
              )
              .map(suite => {
                return {
                  name: suite.title,
                  rateCode: suite.rateCode
                };
              }),
            suiteStyle: isCaliforniaResident
              ? 'version G'
              : isJVersionAndIsTwoRatesTypesPresentAndNonTwoRatesPresent
              ? 'version J and default'
              : 'default'
          };
          if (JSON.stringify(latestVersionJEventErrorObj) !== JSON.stringify(variationJObj)) {
            trackVariationJEventError(EVENT_CATEGORIES.OnVersionJEventError, variationJObj);
            dispatch({
              type: plansActionTypes.SET_PLANS_LATEST_J_VERSION_EVENT_ERROR,
              variationJObj
            });
          }
        }
      }
      //End version J tracking code
      //=============================

      dispatch({
        type: plansActionTypes.GET_PLANS_AVAILABILITY_INFO_SUCCESS,
        availabilityList: availabilityList,
        isAPScall: existsValidSuite ? response.data.suites.some(st => st.availabilityKey) : false,
        isTwoRateTypesSuites: isTwoRateTypesSuites,
        availabilityReqIsLoading: false,
        requiresQualifyingID: isQualifyIdRequired,
        isErrorModalAlreadyClosed: false,
        promoDescription: promoDescription,
        personalizationRules: personalizationRules,
        personalizationResponses: personalizationResponses,
        offerCodeError: hasErrorCode
          ? {
              errorCode: errorCode,
              offerCode: getNormalizedCode(requestObject.offerCode)
            }
          : undefined,
        offerType: offerType,
        lcoPackages: lcoPackages,
        upsellPackagesContent: upsellPackagesContent
      });
      if (hasErrorCode)
        gaTrackingPlanSearchErrorCode(
          JSON.stringify({
            errorCode: errorCode,
            offerCode: getNormalizedCode(requestObject.offerCode)
          })
        );
    },
    err => {
      dispatch({
        type: plansActionTypes.GET_PLANS_AVAILABILITY_INFO_ERROR,
        shouldIgnoreOfferCodeError: shouldIgnoreOfferCodeError,
        availabilityList: [],
        promoDescription: '',
        requiresQualifyingID: false,
        availabilityReqIsLoading: false,
        availabilityRequestFailed: true,
        availabilityErrorMessage: `Oops, ${typeof err === 'string' ? err : err.message}`,
        offerCodeError: undefined
      });
      gaTrackingPlanSearchErrorCode(JSON.stringify(err));
    }
  );
};
export const getAvailabilityHighestRates = requestObject => (dispatch, getState) => {
  dispatch({
    type: plansActionTypes.GET_PLANS_AVAILABILITY_HIGHEST_RATES,
    availabilityHighestRatesReqIsLoading: true
  });

  const apiClient = new RoomBookingApiClient();

  apiClient
    .getAvailabilityHighestRates(requestObject, getResortLocation(getState().currentLodge))
    .then(response => {
      dispatch({
        type: plansActionTypes.GET_PLANS_AVAILABILITY_HIGHEST_RATES_SUCCESS,
        availabilityHighestRatesList: response.data
      });
    })
    .catch(err => {
      dispatch({
        type: plansActionTypes.GET_PLANS_AVAILABILITY_HIGHEST_RATES_ERROR,
        availabilityHighestRatesList: []
      });
    });
};
export const setValidationError = validationError => dispatch => {
  dispatch({
    type: plansActionTypes.GET_PLANS_AVAILABILITY_INFO_ERROR,
    availabilityList: [],
    availabilityRequestFailed: true,
    availabilityErrorMessage: `Oops, we have an error with: ${validationError.path}. Error: ${validationError.type}`
  });
};
export const getRecentSearches = () => dispatch => {
  dispatch({
    type: plansActionTypes.GET_PLANS_RECENT_SEARCHES_INFO_LOADING,
    searchesGetReqIsLoading: true
  });

  const recentSearchesRepository = new RecentSearchesRepository();
  const request = recentSearchesRepository.getAllRecentSearches();

  return request.then(
    response => {
      dispatch({
        type: plansActionTypes.GET_PLANS_RECENT_SEARCHES_INFO_SUCCESS,
        recentSearchesList: response
      });
      dispatch({
        type: plansActionTypes.GET_PLANS_RECENT_SEARCHES_INFO_LOADING,
        searchesGetReqIsLoading: false
      });
    },
    err => {
      dispatch({
        type: plansActionTypes.GET_PLANS_RECENT_SEARCHES_INFO_ERROR,
        recentSearchesList: [],
        searchesReqFailed: true,
        searchesErrorMessage: `Oops, ${err.message}`
      });
      dispatch({
        type: plansActionTypes.GET_PLANS_RECENT_SEARCHES_INFO_LOADING,
        searchesGetReqIsLoading: false
      });
    }
  );
};
export const addRecentSearch = (recentSearchesList, availabilityData) => dispatch => {
  dispatch({
    type: plansActionTypes.SET_PLANS_REQUEST_RECENT_SEARCHES_INFO_SUCCESS,
    searchesSetReqIsLoading: true
  });

  let recentSearchData = {
    checkin: availabilityData.arrival,
    checkout: availabilityData.departure,
    num_adults: availabilityData.numberOfAdults,
    num_children: availabilityData.numOfBillableChildren,
    num_children2: availabilityData.numOfUnbillableChildren,
    property: availabilityData.location,
    suite: availabilityData.suite ? availabilityData.suite : '',
    offer_code: getNormalizedCode(availabilityData.offerCode),
    kids_ages: availabilityData.kidsAges
  };

  const recentSearchesRepository = new RecentSearchesRepository();
  const request = recentSearchesRepository.addNewRecentSearch(recentSearchesList, recentSearchData);

  return request.then(
    response => {
      dispatch({
        type: plansActionTypes.GET_PLANS_RECENT_SEARCHES_INFO_SUCCESS,
        recentSearchesList: response
      });
      dispatch({
        type: plansActionTypes.SET_PLANS_REQUEST_RECENT_SEARCHES_INFO_SUCCESS,
        searchesSetReqIsLoading: false
      });
    },
    err => {
      dispatch({
        type: plansActionTypes.GET_PLANS_RECENT_SEARCHES_INFO_ERROR,
        searchesReqFailed: true,
        searchesErrorMessage: `Oops, ${err.message}`
      });
      dispatch({
        type: plansActionTypes.SET_PLANS_REQUEST_RECENT_SEARCHES_INFO_SUCCESS,
        searchesSetReqIsLoading: false
      });
    }
  );
};
export const deleteRecentSearch = (recentSearchesList, recentSearchId) => dispatch => {
  dispatch({
    type: plansActionTypes.DEL_PLANS_REQUEST_RECENT_SEARCHES_INFO_SUCCESS,
    searchesDelReqIsLoading: true
  });

  const recentSearchesRepository = new RecentSearchesRepository();
  const request = recentSearchesRepository.deleteRecentSearchById(recentSearchesList, recentSearchId);

  return request.then(
    response => {
      dispatch({
        type: plansActionTypes.GET_PLANS_RECENT_SEARCHES_INFO_SUCCESS,
        recentSearchesList: response
      });
      dispatch({
        type: plansActionTypes.DEL_PLANS_REQUEST_RECENT_SEARCHES_INFO_SUCCESS,
        searchesDelReqIsLoading: false
      });
    },
    err => {
      dispatch({
        type: plansActionTypes.GET_PLANS_RECENT_SEARCHES_INFO_ERROR,
        recentSearchesList: [],
        searchesReqFailed: true,
        searchesErrorMessage: `Oops, ${err.message}`
      });
      dispatch({
        type: plansActionTypes.DEL_PLANS_REQUEST_RECENT_SEARCHES_INFO_SUCCESS,
        searchesDelReqIsLoading: false
      });
    }
  );
};
export const deleteAllRecentSearches = () => dispatch => {
  dispatch({
    type: plansActionTypes.DEL_PLANS_REQUEST_RECENT_SEARCHES_INFO_SUCCESS,
    searchesDelReqIsLoading: true
  });

  const recentSearchesRepository = new RecentSearchesRepository();
  const request = recentSearchesRepository.deleteAllRecentSearches();

  return request.then(
    response => {
      dispatch({
        type: plansActionTypes.GET_PLANS_RECENT_SEARCHES_INFO_SUCCESS,
        recentSearchesList: response
      });
      dispatch({
        type: plansActionTypes.DEL_PLANS_REQUEST_RECENT_SEARCHES_INFO_SUCCESS,
        searchesDelReqIsLoading: false
      });
    },
    err => {
      dispatch({
        type: plansActionTypes.GET_PLANS_RECENT_SEARCHES_INFO_ERROR,
        recentSearchesList: [],
        searchesReqFailed: true,
        searchesErrorMessage: `Oops, ${err.message}`
      });
      dispatch({
        type: plansActionTypes.DEL_PLANS_REQUEST_RECENT_SEARCHES_INFO_SUCCESS,
        searchesDelReqIsLoading: false
      });
    }
  );
};
export const openRatesCalendar = (suiteCode, requestObject, getFetchRestrictionsEnabled) => async dispatch => {
  const eventData = rateCalendarGA(GTM_EVENT.ACTION.openRateCalendar, GTM_EVENT.LABEL.greatWolf);

  pushEvent(eventData);
  // Reset the range by suite variables
  dispatch({
    type: plansActionTypes.GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_RESET
  });
  dispatch({
    type: plansActionTypes.SELECT_PLANS_SUITE_FOR_RANGE_TYPE,
    selectedSuiteForRange: suiteCode
  });
  dispatch({
    type: plansActionTypes.GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_LOADING,
    getAvRangeBySuiteIsLoading: true
  });

  try {
    const calendarRates = await getCalendarRates(requestObject, suiteCode, dispatch);
    dispatch(getCalendarRangeRestrictions(requestObject, calendarRates, getFetchRestrictionsEnabled));
  } catch (err) {
    dispatch({
      type: plansActionTypes.GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_ERROR,
      getAvRangeBySuiteFailed: true,
      getAvRangeBySuiteErrorMsg: `Oops, ${err.message}`
    });
    dispatch({
      type: plansActionTypes.GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_LOADING,
      getAvRangeBySuiteIsLoading: false
    });
  }
};
export const getDataForSelectionInRateCalendar = (suiteCode, requestObject) => async (dispatch, getState) => {
  const state = getState()?.plans?.selectedSuiteRange;
  const selectedCalendarRates = await getLowestRatesByStay(state, requestObject, suiteCode);
  dispatch({
    type: plansActionTypes.OPEN_PLANS_RATES_CALENDAR,
    selectedSuiteRange: { ...state, ...selectedCalendarRates }
  });
};
export const closeRatesCalendar = () => {
  const eventData = rateCalendarGA(GTM_EVENT.ACTION.closeRateCalendar, GTM_EVENT.LABEL.greatWolf);
  pushEvent(eventData);
  return {
    type: plansActionTypes.CLOSE_PLANS_RATES_CALENDAR
  };
};
export const getCalendarRangeRestrictions = (requestObject, calendarRates, restrictionsEnabled) => (
  dispatch,
  getState
) => {
  const resortLocation = getResortLocation(getState().currentLodge);
  if (restrictionsEnabled && !restrictionsEnabled) {
    if (calendarRates) {
      dispatch({
        type: plansActionTypes.OPEN_PLANS_RATES_CALENDAR,
        selectedSuiteRange: calendarRates
      });
    }
    dispatch({
      type: plansActionTypes.GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_LOADING,
      getAvRangeBySuiteIsLoading: false
    });
    return;
  }

  if (!requestObject.propertyCode) {
    requestObject = {
      ...requestObject,
      propertyCode: resortLocation
    };
  }

  let newRequest = {
    lodgeCode: requestObject?.lodgeCode || requestObject.location,
    year: requestObject.arrival?.split('-')[0] || requestObject.year,
    month: requestObject.arrival?.split('-')[1] || requestObject.month,
    adults: requestObject.adults || requestObject.numberOfAdults,
    kidsAges: requestObject?.kidsAges,
    offerCode: requestObject.rateCode || requestObject.offerCode || '',
    numberOfMonths: requestObject.numberOfMonths ? requestObject.numberOfMonths : calendarRates ? 2 : 1
  };

  if (requestObject.type) newRequest.type = requestObject.type;

  let apiClient = new RoomBookingApiClient();
  const request = apiClient.getLowestRates(newRequest, requestObject.propertyCode);

  return request.then(
    response => {
      const dealsSummary = response.data.dealsSummary && getTheFirstElementOfArray(response.data.dealsSummary);
      const restrictions = transformRestrictions(response.data)?.restrictions;
      if (calendarRates) {
        dispatch({
          type: plansActionTypes.OPEN_PLANS_RATES_CALENDAR,
          selectedSuiteRange: calendarRates,
          dealsSummary: dealsSummary
        });
      }

      if (newRequest.offerCode !== '') {
        dispatch({
          type:
            requestObject.type === 1
              ? plansActionTypes.GET_PLANS_LOWEST_RATES_CALENDAR_MOBILE
              : plansActionTypes.GET_PLANS_LOWEST_RATES_CALENDAR,
          lowestRateCalendarList: response.data
        });
      }

      dispatch({
        type:
          requestObject?.type === 1
            ? plansActionTypes.OPEN_PLANS_RATES_CALENDAR_RESTRICTION_INCREMENT
            : plansActionTypes.OPEN_PLANS_RATES_CALENDAR_RESTRICTION,
        selectedSuiteRangeRestrictions: restrictions,
        dealsSummary: dealsSummary
      });

      dispatch({
        type: plansActionTypes.GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_LOADING,
        getAvRangeBySuiteIsLoading: false
      });
    },
    err => {
      dispatch({
        type: plansActionTypes.GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_ERROR,
        getAvRangeBySuiteFailed: true,
        getAvRangeBySuiteErrorMsg: `Oops, ${err.message}`
      });
      dispatch({
        type: plansActionTypes.GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_LOADING,
        getAvRangeBySuiteIsLoading: false
      });
    }
  );
};
export const cleanCalendarRestrictionsAndLowestRates = () => {
  return {
    type: plansActionTypes.CLEAN_PLANS_CALENDAR_RESTRICTIONS_AND_LOWEST_RATES
  };
};
export const cleanRatesCalendarRestrictions = () => {
  return {
    type: plansActionTypes.CLOSE_PLANS_RATES_CALENDAR_RESTRICTION
  };
};
export const openPaymentLeaveModal = () => {
  return {
    type: plansActionTypes.OPEN_PLANS_PAYMENT_LEAVE_MODAL
  };
};
export const closePaymentLeaveModal = () => {
  return {
    type: plansActionTypes.CLOSE_PLANS_PAYMENT_LEAVE_MODAL
  };
};
export const setAppliedFilters = params => dispatch => {
  dispatch({
    type: plansActionTypes.SET_PLANS_APPLIED_FILTERS,
    filtersApplied: params
  });
};
export const setSelectedSuiteForCalendar = params => dispatch => {
  dispatch({
    type: plansActionTypes.SET_PLANS_SELECTED_SUITE_NAME_FOR_CALENDAR,
    selectedSuiteForCalendar: params
  });
};

/**
 * @function
 * Sets and toggle when a campaign is being performed on the application
 * @param {bool} isEmailCampaign Whenever if the current url refers to a
 * campaign
 * @param {Object} campaignObject The campaign information
 * @param {date} startDate Start date of the campaign
 * @param {date} endDate End date of the campaign
 */
export const toggleEmailCampaigns = (
  isEmailCampaign,
  campaignObject = {},
  startDate = '',
  endDate = ''
) => dispatch => {
  dispatch({
    type: plansActionTypes.TOGGLE_PLANS_EMAIL_CAMPAIGNS,
    emailCampaign: {
      isEmailCampaign: isEmailCampaign,
      planCalendarShouldBeBlocked: isEmailCampaign,
      startDate: startDate,
      endDate: endDate,
      campaignObject: campaignObject
    }
  });
};

/**
 * @function
 * Clear/reset all the availability data to the initial state   *
 */
export const clearAllAvaliabilityData = () => ({ type: plansActionTypes.CLEAR_PLANS_ALL_AVAILABILITY_DATA });
export const setQualifyingID = (code, x) => {
  return {
    type: plansActionTypes.SET_PLANS_QUALIFYING_ID,
    code: code
  };
};
export const getQualifyingID = (shouldTriggerValidation = false) => (dispatch, getState) => {
  const state = getState();
  const { requiresQualifyingID, qualifyingID } = state.plans;
  if (!requiresQualifyingID) return;

  if (!qualifyingID && shouldTriggerValidation) {
    dispatch({
      type: plansActionTypes.GET_PLANS_QUALIFYING_ID_HAS_ERROR,
      hasError: true
    });
  }
  return qualifyingID && qualifyingID.toUpperCase();
};

/**
 * @function
 * Sets the suite type when an email campaign comes
 * @param {Object} campaignObject The campaign information
 */
export const setEmailCampaingSuiteType = (suiteType = null) => (dispatch, getState) => {
  let normalizedSuiteType;

  if (suiteType && suiteType !== SUITE_TYPES.unknown) {
    switch (suiteType.toLowerCase()) {
      case SUITE_TYPES.standard:
        normalizedSuiteType = SUITE_TYPES.standard;
        break;
      case SUITE_TYPES.themed:
        normalizedSuiteType = SUITE_TYPES.themed;
        break;
      case SUITE_TYPES.premium:
        normalizedSuiteType = SUITE_TYPES.premium;
        break;
      default:
        normalizedSuiteType = SUITE_TYPES.unknown;
    }
  }

  dispatch({
    type: plansActionTypes.SET_PLANS_TAB_INDEX_BY_EMAIL_CAMPAIGN,
    emailCampaignSuiteType: normalizedSuiteType
  });
};

export const setIsBookingWidgetFocused = (value = false) => dispatch => {
  dispatch({
    type: plansActionTypes.SET_PLANS_IS_BOOKING_WIDGET_FOCUSED,
    value: value
  });
};

export const setSortingOrder = sortingType => ({
  type: plansActionTypes.SET_PLANS_SORTING_ORDER,
  sortingType
});
export const setSortSuite = suiteCode => ({
  type: plansActionTypes.SET_PLANS_SUITE_CODE_FILTER,
  suiteCode
});
export const setIsCaliforniaResident = isCaliforniaResident => dispatch => {
  dispatch({
    type: plansActionTypes.SET_PLANS_IS_CALIFORNIA_RESIDENT,
    isCaliforniaResident
  });
};
export const cleanIsCaliforniaResident = () => dispatch => {
  dispatch({
    type: plansActionTypes.CLEAN_PLANS_IS_CALIFORNIA_RESIDENT
  });
};
