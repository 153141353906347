// Offer Actions

import { getNormalizedCode } from '../../../utilities/availabilityUtils';
import offerActionTypes from './offer.types';

export const addOfferCode = code => ({
  type: offerActionTypes.ADD_OFFER_CODE,
  code: getNormalizedCode(code)
});

export const removeOfferCode = () => ({ type: offerActionTypes.REMOVE_OFFER_CODE });

export const setOfferCodeDescription = description => dispatch => {
  dispatch({
    type: offerActionTypes.SET_OFFER_CODE_DESCRIPTION,
    description: description
  });
};
export const addActiveOffer = offerData => dispatch => {
  dispatch({
    type: offerActionTypes.ADD_OFFER_ACTIVE_TYPE,
    offerData: offerData
  });
};
export const addEmailActiveOffer = offerData => dispatch => {
  dispatch({
    type: offerActionTypes.ADD_OFFER_EMAIL_ACTIVE_TYPE,
    offerData: offerData
  });
};
export const removeActiveOffer = offerData => dispatch => {
  dispatch({
    type: offerActionTypes.REMOVE_OFFER_ACTIVE_TYPE,
    offerData: offerData
  });
};
