import ReactGA from 'react-ga';
import { getLocation } from '../components/utilities/ResortLocations';
import { buildCheckoutEventObject } from '../infrastructure/middleware/analytics/analyticsObjectBuilders';
import { pushEvent } from '../utilities/dataLayerUtils';
// Auth
import { v4 as uuidv4 } from 'uuid';
import AuthClient from '../api/auth/AuthClient';
import { GwDatesWrapper } from '../components/_internal_date_/gwDatesWrapper';
import { getResortLocation } from '../store/componentStores/CurrentLodge/currentLodge.selectors';
import { store } from '../store/configureStore';
import { DATE_FORMATS } from './constants';

/**
 * Categories used to trigger Google Analytics events.
 * @enum {string}
 */
export const EVENT_CATEGORIES = {
  arrivalDate: 'Search Arrival Date',
  departureDate: 'Search Departure Date',
  numberOfNights: 'Search Number of Nights',
  occupants: 'Search Occupants',
  promoCode: 'Search Promo',
  userType: 'User Type',
  dayPassInteraction: 'Day Pass Interaction',
  suiteInteraction: 'Suite Interaction',
  findYourReservation: 'Find Your Reservation',
  navigation: 'Navigation',
  leadGenerationForm: 'Lead Generation Form',
  specialRequests: 'Special Requests',
  celebration: 'Celebration',
  backendError: 'BE Error Messaging',
  searchDayPass: 'Search Day Pass',
  OWSError: 'OwsError',
  postPackageSale: 'Post Conversion - Package Sale',
  postPackageInteraction: 'Post Conversion - Package Interaction',
  optinModalUserClose: 'User closed the OPT-IN modal',
  optinModalUserErrorClose: 'Error closed the OPT-IN modal',
  bonusDealClicked: 'User clicked bonus deal'
};

/**
 * Event actions to push to Google analytics.
 * @enum {string}
 */
export const EVENT_ACTIONS = {
  login: 'Logged In - MGW',
  dayPassPageVisit: 'daypass_page_visit',
  dayPassPaymentVisit: 'daypass_page_payment_visit',
  dayPassSearchDate: 'daypass_page_searchdate',
  dayPassSearchGuests: 'daypass_page_searchguests',
  dayPassEnterPromoCode: 'daypass_page_enter_promocode',
  dayPassPageConfirmationVisit: 'daypass_page_confirmation_visit',
  dayPassEnterQualifyingId: 'daypass_page_enter_qualifying_id',
  dayPassCheckout: 'daypass_page_checkout',
  dayPassBooking: 'daypass_click_agree_buy',
  suiteCheckoutRoomRestrictedError: 'suite_page_checkout_room_restricted_error',
  suiteCheckoutRateUnavailableError: 'suite_page_checkout_rate_unavailable_error',
  suiteCheckoutRateCodeUnavailableError: 'suite_page_checkout_rate_code_unavailable_error',
  suiteCheckoutRateMismatchError: 'suite_page_checkout_rate_mismatch_error',
  suiteCheckoutPropertyRestrictedError: 'suite_page_checkout_property_restricted_error',
  suiteCheckoutRoomUnavailableError: 'suite_page_checkout_room_unavailable_error',
  suitePlanSearchInactiveOfferCodeError: 'suite_page_plan_search_inactive_offer_code_error',
  suitePlanSearchInvalidOfferCodeError: 'suite_page_plan_search_invalid_offer_code_error',
  suitePlanSearchMaximumAdvanceBookingError: 'suite_page_plan_search_maximum_advance_booking_error',
  suitePlanSearchMinimumAdvanceBookingError: 'suite_page_plan_search_minimum_advance_booking_error',
  suitePlanSearchMinStayError: 'suite_page_plan_search_min_stay_error',
  suitePlanSearchMaxStayError: 'suite_page_plan_search_max_stay_error',
  suitePlanSearchInactivePromoError: 'suite_page_plan_search_inactive_promo_error',
  suitePlanSearchRoomUnavailableError: 'suite_page_plan_search_room_unavailable_error',
  suitePlanSearchRoomRestrictedError: 'suite_page_plan_search_room_restricted_error',
  suitePlanSearchRateCodeNotAvailableError: 'suite_page_plan_search_rate_code_not_available_error',
  suitePlanSearchRatesMismatchError: 'suite_page_plan_search_rates_mismatch_error',
  findYourReservationConfirmationNumber: 'Entered Confirmation Number',
  findYourReservationLogin: 'Login Success',
  findYourReservationLoginFail: 'Login Fail',
  navMyProfile: 'nav_myprofile',
  navLogout: 'nav_logout',
  navFindMyRes: 'nav_findmyres',
  navSignIn: 'nav_signin',
  navSignUp: 'nav_signup',
  navRecentSearches: 'nav_recentsearches',
  modalClosed: 'modal_closed',
  specialRequestDropdownClick: 'Click on Special Request Dropdown',
  specialOccasionDropdownClick: 'Click on Special Occasion Dropdown',
  packageAdded: 'Added Package',
  packageRemoved: 'Removed Package',
  viewPackageDetails: 'View Details Toggled',
  optinModalUserClose: 'user_closed_optin_modal',
  optinModalUserErrorClose: 'error_closed_optin_modal',
  bonusDealClicked: 'Bonus Deal Clicked',
  viewItemList: 'view_item_list'
};

let location = '';

export const setLocation = resortLocation => (location = getLocation(resortLocation).display);

export const logOWSError = message => {
  if (!message) return;

  ReactGA.event({
    category: EVENT_CATEGORIES.OWSError,
    label: location,
    action: message
  });
};

export const logOfferCodeError = message => {
  if (!message) return;

  ReactGA.event({
    category: EVENT_CATEGORIES.backendError,
    label: location,
    action: message
  });
};

export const logFindYourReservationLoginSuccess = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.findYourReservation,
    label: location,
    action: EVENT_ACTIONS.findYourReservationLogin
  });
};

export const logFindYourReservationLoginFail = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.findYourReservation,
    label: location,
    action: EVENT_ACTIONS.findYourReservationLoginFail
  });
};

export const logFindYourReservationConfNumber = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.findYourReservation,
    label: location,
    action: EVENT_ACTIONS.findYourReservationConfirmationNumber
  });
};

export const logDayPassBooking = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.dayPassInteraction,
    label: location,
    action: EVENT_ACTIONS.dayPassBooking
  });
};

export const logDayPassCheckout = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.dayPassInteraction,
    label: location,
    action: EVENT_ACTIONS.dayPassCheckout
  });
};

export const logDayPassSearchPromoCode = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.dayPassInteraction,
    label: location,
    action: EVENT_ACTIONS.dayPassEnterPromoCode
  });
};

export const logDayPassSearchGuest = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.dayPassInteraction,
    label: location,
    action: EVENT_ACTIONS.dayPassSearchGuests
  });
};

export const logDayPassSearchDate = date => {
  logDayPassSearch(date);
  logDayPassOnDateChange();
};

export const logDayPassSearch = date => {
  if (!date) return;

  ReactGA.event({
    category: EVENT_CATEGORIES.searchDayPass,
    label: location,
    action: date
  });
};

export const logDayPassOnDateChange = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.dayPassInteraction,
    label: location,
    action: EVENT_ACTIONS.dayPassSearchDate
  });
};

export const logDayPassPageVisit = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.dayPassInteraction,
    label: location,
    action: EVENT_ACTIONS.dayPassPageVisit
  });
};

export const logDayPassPaymentVisit = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.dayPassInteraction,
    label: location,
    action: EVENT_ACTIONS.dayPassPaymentVisit
  });
};

export const logDayPassConfirmationVisit = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.dayPassInteraction,
    label: location,
    action: EVENT_ACTIONS.dayPassPageConfirmationVisit
  });
};

export const logSuiteCheckoutError = errorType => {
  if (!errorType) return;

  ReactGA.event({
    category: EVENT_CATEGORIES.suiteInteraction,
    label: location,
    action: errorType
  });
};

export const logSuitePlanSearchError = errorType => {
  if (!errorType) return;

  ReactGA.event({
    category: EVENT_CATEGORIES.suiteInteraction,
    label: location,
    action: errorType
  });
};

export const logLoggedInUser = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.userType,
    label: location,
    action: EVENT_ACTIONS.login
  });
};

export const logBookingWidgetSearch = ({ arrivalDate, departureDate, numberOfNights, occupants, promoCode }) => {
  logSearchArrivalDate(arrivalDate);
  logSearchDepartureDate(departureDate);
  logSearchNumberOfNights(numberOfNights);
  logSearchOccupants(occupants);
  logSearchPromoCode(promoCode);
};

export const logSearchArrivalDate = arrivalDate => {
  if (!arrivalDate) return;

  ReactGA.event({
    category: EVENT_CATEGORIES.arrivalDate,
    label: location,
    action: arrivalDate
  });
};

export const logSearchDepartureDate = departureDate => {
  if (!departureDate) return;

  ReactGA.event({
    category: EVENT_CATEGORIES.departureDate,
    label: location,
    action: departureDate
  });
};

export const logSearchNumberOfNights = numberOfNights => {
  if (!numberOfNights) return;

  ReactGA.event({
    category: EVENT_CATEGORIES.numberOfNights,
    label: location,
    action: numberOfNights.toString()
  });
};

export const logSearchOccupants = occupants => {
  if (!occupants) return;

  ReactGA.event({
    category: EVENT_CATEGORIES.occupants,
    label: location,
    action: occupants.toString()
  });
};

export const logSearchPromoCode = promoCode => {
  if (!promoCode) return;

  ReactGA.event({
    category: EVENT_CATEGORIES.promoCode,
    label: location,
    action: promoCode
  });
};

export const logMyProfileNav = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.navigation,
    label: location,
    action: EVENT_ACTIONS.navMyProfile,
    nonInteraction: true
  });
};

export const logLogout = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.navigation,
    label: location,
    action: EVENT_ACTIONS.navLogout,
    nonInteraction: true
  });
};

export const logFindMyResNav = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.navigation,
    label: location,
    action: EVENT_ACTIONS.navFindMyRes,
    nonInteraction: true
  });
};

export const logSignInNav = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.navigation,
    label: location,
    action: EVENT_ACTIONS.navSignIn,
    nonInteraction: true
  });
};

export const logSignUpNav = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.navigation,
    label: location,
    action: EVENT_ACTIONS.navSignUp,
    nonInteraction: true
  });
};

export const logLeadGenModalClose = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.leadGenerationForm,
    label: location,
    action: EVENT_ACTIONS.modalClosed,
    nonInteraction: true
  });
};

export const logRecentSearchesNav = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.navigation,
    label: location,
    action: EVENT_ACTIONS.navRecentSearches,
    nonInteraction: true
  });
};

export const logSpecialRequestOrOccasionClick = (category, action) => {
  if (!category || !action) return;

  ReactGA.event({
    category: category,
    label: location,
    action: action,
    nonInteraction: true
  });
};

export const logSpecialRequestsClick = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.specialRequests,
    label: location,
    action: EVENT_ACTIONS.specialRequestDropdownClick,
    nonInteraction: true
  });
};

export const logSpecialOccasionClick = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.celebration,
    label: location,
    action: EVENT_ACTIONS.specialOccasionDropdownClick,
    nonInteraction: true
  });
};

export const logPostPackageSale = packagesBeingAdded => {
  packagesBeingAdded &&
    packagesBeingAdded.forEach(pkg => {
      ReactGA.event({
        category: EVENT_CATEGORIES.postPackageSale,
        label: location,
        action: pkg.title,
        value: pkg.packageAmount * pkg.quantityAdded,
        nonInteraction: true
      });
    });
};

export const logAddedPackagePostConversion = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.postPackageInteraction,
    label: location,
    action: EVENT_ACTIONS.packageAdded
  });
};

export const logRemovedPackagePostConversion = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.postPackageInteraction,
    label: location,
    action: EVENT_ACTIONS.packageRemoved
  });
};

export const logViewPackageDetailsPostConversion = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.postPackageInteraction,
    label: location,
    action: EVENT_ACTIONS.viewPackageDetails
  });
};

/**
 * Listener on checkout page diff events
 * @param {numbers} step, current step of the checkout page process
 * @param {string} option, selected payment type credit-card|paypal|affirm
 */
export const sendCheckoutStep = (step = 1, paymentMethod = '') => {
  try {
    const { suite, guests, dates, currentLodge } = store.getState();
    const resortLocation = getResortLocation(currentLodge);
    const selectedSuite = suite.selectedSuite;
    if (!selectedSuite || !guests || !dates) return;

    const authClient = new AuthClient();
    const isUserloggedIn = authClient.isLoggedIn();

    const checkoutEventObject = buildCheckoutEventObject(
      selectedSuite,
      guests,
      dates,
      resortLocation,
      step,
      isUserloggedIn,
      paymentMethod
    );
    pushEvent(checkoutEventObject);
  } catch (error) {}
};

export const optinModalUserClose = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.optinModalUserClose,
    label: location,
    action: EVENT_ACTIONS.optinModalUserClose
  });
};

export const optinModalUserErrorClose = () => {
  ReactGA.event({
    category: EVENT_CATEGORIES.optinModalUserErrorClose,
    label: location,
    action: EVENT_ACTIONS.optinModalUserErrorClose
  });
};

// LOYALTY EVENTS
export const logBonusDealApplied = () => {
  const { offer } = store.getState();
  offer.activeOffers &&
    offer.activeOffers.forEach(activeOffer =>
      ReactGA.event({
        category: EVENT_CATEGORIES.bonusDealClicked,
        label: activeOffer,
        action: EVENT_ACTIONS.bonusDealClicked
      })
    );
};

//Birthday events
export const searchBirthdayParty = values => {
  const {
    partyDate,
    partyTime,
    partyGuests: { partyAdults, partyKids, partyInfant, partySpectators }
  } = values;

  const searchEventObject = {
    event: 'search',
    resortLocation: location,
    searchType: 'birthday',
    bonus_deal_value: '',
    searchLocation: 'standard purchase',
    arrival_date: partyDate,
    departure_date: partyDate,
    numberOfAdults: partyAdults,
    numberOfKids: partyKids,
    numberOfInfants: partyInfant,
    numberOfSpectators: partySpectators,
    kidAges: 0,
    discountCode: '',
    NumNights: 0,
    party_time: partyTime
  };

  pushEvent(searchEventObject);
};

export const addBirthdayPartyToCart = () => {
  const { birthdayParty } = store.getState();
  console.log('birthday', birthdayParty);
  const addToCartEventObject = {
    event: 'add-to-cart',
    resortLocation: location,
    purchaseLocation: 'standard purchase – birthday party',
    ecommerce: {},
    currencyCode: 'USD',
    add: {
      actionField: {
        list: 'packages',
        position: '',
        coupon: ''
      },
      products: [
        {
          name: birthdayParty.selectedParty.partyType,
          id: birthdayParty.selectedParty.priceDetails.packageCode,
          price: birthdayParty.selectedParty.priceDetails.amountTotal,
          brand: 'great wolf',
          category: 'birthday party',
          variant: 'birthday party',
          quantity: 1,
          dimension27: '',
          dimension28: '',
          dimension29: location,
          dimension30: 'packages',
          dimension31: 0,
          dimension32: birthdayParty.partyAdults, // adults
          dimension33: birthdayParty.partyKids, //kids
          dimension34: 'standard purchase – birthday party',
          dimension35: birthdayParty.partyDate,
          dimension36: birthdayParty.partyDate,
          dimension61: 20, //max occupancy
          dimension62: [],
          coupon: '',
          dimension64: birthdayParty.partyInfant, // product scope dimension for number of infants​
          dimension65: birthdayParty.partySpectators, // product scope dimension for number of spectators​
          dimension67: birthdayParty.partyTime //product scope dimension for party time
        }
      ]
    }
  };

  pushEvent(addToCartEventObject);
};

export const birthdayPartySuccessfulTransaction = birthdayFlowPayload => {
  const { reservationId, partyName, date, total } = birthdayFlowPayload;
  const { birthdayParty } = store.getState();
  const analyticsFormatDate = GwDatesWrapper.format(date, DATE_FORMATS.default);
  const successfulTransactionEventObject = {
    event: 'transaction',
    resortLocation: 'illinois',

    purchaseLocation: 'standard purchase – birthday party',

    ecommerce: {
      currencyCode: 'USD',
      purchase: {
        actionField: {
          id: uuidv4(), // random unique code​​
          affiliation: 'great wolf',
          revenue: birthdayParty.selectedParty.priceDetails.amountTotal,
          shipping: '0.00',
          tax: birthdayParty.selectedParty.priceDetails.amountTax,
          coupon: '',
          voyagerPointsDiscount: 0,
          offersPointsDiscount: 0,
          SIP: 2,
          ConfirmationNo: reservationId, // Pass along APS/Opera confirmation no.​
          products: [
            {
              name: partyName,
              id: birthdayParty.selectedParty.priceDetails.packageCode, // product/item ID
              price: total,
              brand: 'great wolf',
              category: 'birthday party',
              variant: 'birthday party',
              quantity: 1,
              coupon: '',
              dimension29: location,
              dimension30: 'suites',
              dimension31: 0,
              dimension32: birthdayParty.partyAdults, // adults
              dimension33: birthdayParty.partyKids, // kids
              dimension34: 'standard purchase – birthday party',
              dimension35: analyticsFormatDate,
              dimension36: analyticsFormatDate,
              dimension61: 20, //max occupancy
              dimension62: [],
              dimension63: 'Credit Card', // payment type
              dimension64: birthdayParty.partyInfant, // product scope dimension for number of infants​
              dimension65: birthdayParty.partySpectators, // product scope dimension for number of spectators​
              dimension67: birthdayParty.partyTime //product scope dimension for party time​
            }
          ]
        }
      }
    }
  };
  pushEvent(successfulTransactionEventObject);
};
