// import map from 'lodash-es/map';
import get from 'lodash-es/get';
import { GwDatesWrapper } from '../components/_internal_date_/gwDatesWrapper';
import { getFormattedPrice } from './cmpPackagesUtils';
import { DATE_FORMATS } from './constants';

export const getPriceOnDate = cabanaAvailableDates => {
  let prices = [];
  cabanaAvailableDates.forEach(dt => {
    for (const bp in dt) {
      prices.push(dt[bp]['basePrice']);
    }
  });
  return prices;
};

export const getCabanaRates = (selectedCabanasRates, cabanaAvailableDates) => {
  let formatedPrice = 0;
  if (selectedCabanasRates) {
    if (selectedCabanasRates.length < 1) {
      formatedPrice = Math.min(...getPriceOnDate(cabanaAvailableDates));
    } else {
      formatedPrice = selectedCabanasRates
        .map(selectedCabanaDate => get(selectedCabanaDate, 'value.amount', get(selectedCabanaDate, 'packageAmount', 0)))
        .reduce((firstAmount, secondAmount) => firstAmount + secondAmount, 0);
    }
  }

  return `$${getFormattedPrice(formatedPrice)}`;
};

/**
 * Method to get dropdown Items for cabanas. It helps to set selection by default
 * @param {bool} isDayPass Wether if is day pass or not
 * @param {string} reservationArrivalDate First date/or arrival date of the reservation
 * @param {array} cabanaDetails Details of the current cabana to be selected
 * @param {array} daysAvailable Days available for cabanas
 * @param {array} selectedCabanas Packages already selected
 * @param {string} packageCode Current cabana package code
 * @returns
 */
export const getCabanasDropdownItems = (
  isDayPass,
  reservationArrivalDate,
  cabanaDetails,
  daysAvailable,
  selectedCabanas,
  packageCode,
  title,
  rateCode
) => {
  let items = [];
  if (cabanaDetails) {
    cabanaDetails.dates.forEach(cabanasAvailableDates => {
      for (const cabanaDate in cabanasAvailableDates) {
        const isSelected =
          selectedCabanas.some(pkg => pkg.id === rateCode + '~' + packageCode + '~' + cabanaDate) &&
          selectedCabanas.some(pkg => pkg.title === title);
        items.push({
          display: `${GwDatesWrapper.format(cabanaDate, DATE_FORMATS.formatDayMoreDetailed)} - $${
            cabanasAvailableDates[cabanaDate].basePrice
          }`,
          value: {
            amount: cabanasAvailableDates[cabanaDate].basePrice,
            rate: cabanaDetails.rateCode,
            packageCode,
            date: cabanaDate
          },
          isSelected
        });
      }
    });
  }
  return items;
};

/**
 * Function to handle the validation of cabanas days, and filter to valid days only
 * @param {array} cabanaAvailableDates Array of cabanas with available days
 * @returns {array}
 */
export const getValidCabanaDetails = cabanaAvailableDates => {
  const cabanaDetails = cabanaAvailableDates.filter(cabanaDetail => {
    const hasPriceData = cabanaDetail.total > 0;
    if (cabanaDetail.errorCode || !hasPriceData) {
      return false;
    }
    return true;
  });

  return cabanaDetails;
};

/**
 * Handler function when cabana selector changes, so it triggers the cabanas selection callbacks and updates
 * @param {array} listItems Cabanas to be updated
 * @param {function} handleCabanaSelection Handler callback for cabanas selection
 * @param {function} setCabanasRatesSelection Handler callback for cabanas and rates changed
 */
export const onCabanasItemsChanged = (listItems, handleCabanaSelection, setCabanasRatesSelection) => {
  if (listItems) {
    const selectedItems = listItems.filter(cabanasRates => cabanasRates.isSelected);
    handleCabanaSelection(listItems);
    setCabanasRatesSelection(selectedItems);
  }
};

export const startingFromPrice = cabanaAvailableDates => {
  if (cabanaAvailableDates && cabanaAvailableDates.length > 0) {
    return `From $${Math.min(...getPriceOnDate(cabanaAvailableDates))} / Per Day`;
  }
  return 'Package Not Available';
};

export const getSelectedCabanasByPkgCode = (packageCode, prevSelectedCabanas) => {
  let selectedCabanas = [];

  if (prevSelectedCabanas) {
    try {
      selectedCabanas = prevSelectedCabanas.filter(
        cabanasRates => cabanasRates && cabanasRates.id && cabanasRates.id.split('~')[0] === packageCode
      );
    } catch (error) {}
  }

  return selectedCabanas;
};

export const transformCabanaData = data => {
  const { content, dailyRates } = data;

  const datesArray = dailyRates.map(rate => ({ [rate.date]: { basePrice: rate.amount } }));
  const availabilityCabanas = {
    dates: datesArray,
    description: content.description,
    details: content.details,
    image: content.image,
    rateCode: data.priceCode,
    roomCode: data.productCode,
    title: content.title
  };

  return availabilityCabanas;
};
