import get from 'lodash-es/get';
import uuidv4 from 'uuid/v4';
import { GwDatesWrapper } from '../components/_internal_date_/gwDatesWrapper';
import generateArray from '../components/utilities/generate-array';
import { optimizelyHideActivityTabs, optimizelyHideFamilyTab } from './ABTesting';
import {
  DATE_FORMATS,
  HOWL_N_LEARN_CODE,
  LATE_CHECKOUT_PREFIXES,
  PACKAGE_CHARGE_TYPE,
  PACKAGE_TYPE
} from './constants';

const getDateFromISODateString = ISODateString => {
  return GwDatesWrapper.format(ISODateString, DATE_FORMATS.default);
};

const getDatesFromDateRange = (beginDate, endDate) => {
  const dates = [];

  if (GwDatesWrapper.isSame(beginDate, endDate)) {
    dates.push(beginDate);
  } else {
    for (
      let nextDate = beginDate;
      GwDatesWrapper.isBefore(nextDate, endDate);
      nextDate = GwDatesWrapper.add(nextDate, 1)
    ) {
      dates.push(nextDate);
    }
  }

  return dates;
};

const isInsideRangeInclusive = (checkDate, packageStartDate, packageEndDate) => {
  return (
    GwDatesWrapper.isSameOrAfter(checkDate, packageStartDate) &&
    GwDatesWrapper.isSameOrBefore(checkDate, packageEndDate)
  );
};

/**
 * This function aims to return the filtered reservation dates list
 * @param {Array} reservationDates - Current date
 * @param {Object} datesObj - Start of the date range
 * @param {Boolean} shouldAddFiltered - Should add/remove filtered items
 * @return {Array}
 */
const getReservationDates = (reservationDates, datesObj, shouldAddFiltered = false) => {
  if (!reservationDates || !datesObj) return reservationDates;

  const packageStartDate = getDateFromISODateString(datesObj.packageStartDate);
  const packageEndDate = getDateFromISODateString(datesObj.packageEndDate);

  const reservationDatesAvailable = reservationDates.filter(resDate => {
    const isInsideRange = isInsideRangeInclusive(resDate, packageStartDate, packageEndDate);

    return shouldAddFiltered ? isInsideRange : !isInsideRange;
  });

  return reservationDatesAvailable;
};

export const getPackageContent = (packageContent, checkinDate, checkoutDate, isLateCheckoutOffered) => {
  return getPackageContentAvailability(
    packageContent,
    packageContent.packageCode,
    checkinDate,
    checkoutDate,
    isLateCheckoutOffered
  );
};
/**
 * Returns the available dates configured by the content in the packageTablesJSON file,
 * given a date range and a package code. If no dates are available, the return is undefined.
 * @param {String} packageCode - Package code
 * @param {Date} checkinDate - Initial date
 * @param {Date} checkoutDate - End date
 * @return {Array of Date}
 */
export const getPackageContentAvailability = (
  packageContentData,
  packageCode,
  checkinDate,
  checkoutDate,
  isLateCheckoutOffered
) => {
  if (!checkinDate || !checkoutDate) return;
  const reservationDates = getDatesFromDateRange(checkinDate, checkoutDate);

  if (!packageContentData) return reservationDates;

  const {
    availableDates: contentAvailableDatesArray,
    unavailableDates: contentUnavailableDatesArray
  } = packageContentData;

  const hasAvailableInfo = contentAvailableDatesArray && contentAvailableDatesArray.length > 0;
  const hasUnavailableInfo = contentUnavailableDatesArray && contentUnavailableDatesArray.length > 0;

  if (hasAvailableInfo || hasUnavailableInfo) {
    let availableDates = calculateAvailableDates(
      packageContentData,
      hasAvailableInfo,
      contentAvailableDatesArray,
      reservationDates,
      checkinDate,
      hasUnavailableInfo,
      contentUnavailableDatesArray
    );
    return availableDates.length > 0 ? availableDates : undefined;
  }

  if (packageCode === HOWL_N_LEARN_CODE && !isLateCheckoutOffered) {
    return;
  }

  return reservationDates;
};

export const setUpSellTitleObj = (allPackages, packageTables) => {
  return packageTables
    .filter(pkgTable => pkgTable.buttonLabel && pkgTable.title && pkgTable.description)
    .map(pkgTable => {
      const { buttonLabel, title, description, images, packageCodes = [] } = pkgTable;

      const foundPackage = allPackages.find(pkg => {
        if (pkg.packageType.length > 0) {
          const trimmedPkgsCode = packageCodes.map(pkg => pkg.trim());
          return (
            trimmedPkgsCode.includes(pkg.packageCode || null) ||
            packageCodes[0]?.trim()?.includes(pkg.packageCode || null)
          );
        }
        return false;
      });

      const packageType = foundPackage?.packageType[0];

      return {
        id: uuidv4(),
        buttonLabel,
        title,
        description,
        images,
        packageType
      };
    });
};

/**
 * Check if reservation contains only one package and it is late checkout package only
 * @param {Array} reservationPackages selected reservation packages
 */
export const checkIsLateCheckoutPackageOnly = packagesList => {
  let isLCOPackageOnly = false;
  if (packagesList && packagesList.length === 1) {
    isLCOPackageOnly = packagesList.some(pkg => pkg.packageCode.includes(LATE_CHECKOUT_PREFIXES.code));
  }
  return isLCOPackageOnly;
};

/**
 * Returns the already added packages, it looks for packages recently added in CMP and the packages already added in the Reservation
 * @param {string} packageCode Package code to look for
 * @param {List.<Object>} additionalPackages List of additional Pacakges
 * @param {List.<Object>} suitablePackagesList List of candidate packages to add
 * @param {List.<Object>} reservationPackages Packages already added in the reservation
 * @returns if the package is already added returns an Object with the quantity added.
 * Otherwise the package is not already added it returns null.
 */
export const getAddedPackage = (packageCode, additionalPackages, suitablePackagesList, reservationPackages) => {
  let addedPackage = null;

  addedPackage = additionalPackages.find(additionalPackage => {
    let additionalPackageCode = '';

    if (additionalPackage.id.includes('~')) {
      additionalPackageCode = additionalPackage.id.split('~')[0];
    } else {
      additionalPackageCode = additionalPackage.id;
    }

    return additionalPackageCode === packageCode || additionalPackageCode === suitablePackagesList.itemCode;
  });

  // if there is no packages added
  if (!addedPackage) {
    const resvPackage = reservationPackages.find(
      resvPackage =>
        resvPackage.packageCode === packageCode ||
        (resvPackage.packageGroup && resvPackage.packageGroup === packageCode)
    );

    if (resvPackage) {
      addedPackage = {
        quantityAdded: resvPackage.quantity
      };
    }
  }

  return addedPackage;
};

/**
 * Filter packages on Add a packages to show to the user, we should to hide
 * those packages in function of advance booking days
 * @param {string} resvArrival Reservation arrival date
 * @param {Array} tierToShow List of packages to show to the user
 */
export const filterPkgsByAdvanceBookingDays = (resvArrival, tierToShow) => {
  try {
    let dayDiff = GwDatesWrapper.diff(resvArrival, GwDatesWrapper.today());

    // Defensive to get values greater that 0
    dayDiff = Math.max(dayDiff, 0);
    const filteredPkgs = tierToShow.filter(
      pkg => pkg && pkg.advanceBookingDays && parseInt(pkg.advanceBookingDays) <= dayDiff
    );

    return filteredPkgs;
  } catch (error) {
    return tierToShow;
  }
};

const calculateAvailableDates = (
  packageContentData,
  hasAvailableInfo,
  contentAvailableDatesArray,
  reservationDates,
  checkinDate,
  hasUnavailableInfo,
  contentUnavailableDatesArray
) => {
  let availableDates = [];
  if (
    packageContentData.packageType[0] &&
    packageContentData.packageType[0] === PACKAGE_TYPE.dining &&
    hasAvailableInfo
  ) {
    availableDates = calculateAvailableDatesForDiningPackages(
      contentAvailableDatesArray,
      reservationDates,
      checkinDate,
      availableDates
    );
  } else if (packageContentData.packageType[0] && packageContentData.packageType[0] !== PACKAGE_TYPE.dining) {
    availableDates = calculateAvailableDatesForPackages(
      hasAvailableInfo,
      contentAvailableDatesArray,
      reservationDates,
      hasUnavailableInfo,
      contentUnavailableDatesArray
    );
  }
  return availableDates;
};

const calculateAvailableDatesForDiningPackages = (contentAvailableDatesArray, reservationDates, checkinDate) => {
  let availableDates = [];

  contentAvailableDatesArray.forEach(availableDatesObj => {
    if (calculateCheckinDateInsidePackageDateRange(reservationDates, availableDatesObj, checkinDate)) {
      availableDates = reservationDates;
    }
  });
  return availableDates;
};

const calculateAvailableDatesForPackages = (
  hasAvailableInfo,
  contentAvailableDatesArray,
  reservationDates,
  hasUnavailableInfo,
  contentUnavailableDatesArray
) => {
  let availableDates = [];

  if (hasAvailableInfo) {
    contentAvailableDatesArray.forEach(availableDatesObj => {
      const reservationDatesAvailable = getReservationDates(reservationDates, availableDatesObj, true);

      if (reservationDatesAvailable) {
        availableDates = [...availableDates, ...reservationDatesAvailable];
      }
    });
  }

  if (hasUnavailableInfo) {
    contentUnavailableDatesArray.forEach(availableDatesObj => {
      const datesWithoutUnavailable = getReservationDates(reservationDates, availableDatesObj, false);

      if (datesWithoutUnavailable) {
        availableDates = [...availableDates, ...datesWithoutUnavailable];
      }
    });
  }
  return availableDates;
};

const calculateCheckinDateInsidePackageDateRange = (reservationDates, availableDatesObj, checkinDate) => {
  if (!reservationDates || !availableDatesObj) return reservationDates;

  const packageStartDate = getDateFromISODateString(availableDatesObj.packageStartDate);
  const packageEndDate = getDateFromISODateString(availableDatesObj.packageEndDate);

  return isInsideRangeInclusive(checkinDate, packageStartDate, packageEndDate);
};

/**
 * Gets a set of information needed to fill options logic for packages dropdowns
 * @param {Object} packageItem Package Item incoming from storage
 * @param {Boolean} isNiagaraProperty Whether user is in niagara property
 * @returns {Object} A set of information needed to fill dropdowns logic
 */
export const getDropdownDataForPackage = (packageItem, checkinDateSelection, checkoutDateSelection) => {
  const chargeType = getPackageChargeType(packageItem);

  let maxPackageLimit = 1;
  if (chargeType === PACKAGE_CHARGE_TYPE.byPackage) {
    maxPackageLimit = get(packageItem, 'maxPackageLimit', 9);
  } else {
    const availableDays = getPackageContent(packageItem, checkinDateSelection, checkoutDateSelection);
    maxPackageLimit = Math.max(availableDays?.length, 1);
  }

  const options = getPackageDropdownOptions(maxPackageLimit);

  return {
    quantity: packageItem.quantity,
    chargeType,
    maxPackageLimit,
    options
  };
};

/**
 * Check if we should show package quantity selection
 * @param {String} packageCode Package Code
 * @returns {Boolean}
 */
export const shouldShowQuantitySelect = packageItem => {
  const isDiningPackage = packageItem.packageType?.includes(PACKAGE_TYPE.dining);
  const chargeType = getPackageChargeType(packageItem);

  // We should show the select/dropdown only when the package is not dining and is not late checkout package
  // if the package is dining and charge by package, then we should enable dropdown selection
  return (
    (!isDiningPackage || (isDiningPackage && chargeType === PACKAGE_CHARGE_TYPE.byPackage)) &&
    !packageItem?.packageCode?.includes(LATE_CHECKOUT_PREFIXES.code)
  );
};

export const getPackageChargeType = packageData => {
  const chargeType = get(packageData, 'chargeType', PACKAGE_CHARGE_TYPE.byPackage);

  return chargeType;
};

export const getPackageDropdownOptions = selectLimit => {
  const options = parseInt(selectLimit);

  return generateArray(options).map((_, index) => {
    const value = index + 1;
    return (
      <option key={index} value={value}>
        {value}
      </option>
    );
  });
};

/**
 * Gets the number of available nights for certain package
 * @param {Object} packageItem Package object
 * @param {String} arrival Reservation arrival date in string
 * @param {String} departure Reservation departure date in string
 * @returns Number. Number of available nights for that package
 */
export const getPackagesAvailableNights = (packageItem, arrival, departure) => {
  const availableDays = getPackageContentAvailability(packageItem, packageItem.packageCode, arrival, departure);

  return availableDays ? availableDays.length : 0;
};

/**
 * Check if the current package should be shown for the user, checks for availability number of nights
 * If the package has any amount and if the package is not null or empty
 * @param {Object} packageItem Package to find
 * @param {String} arrival Reservation's arrival date
 * @param {String} departure Reservation's departure date
 * @returns {Object}. shouldBeShown: Whether the package should be shown; numberOfAvailableNights: number of available nights
 */
export const validPackage = (packageItem, arrival, departure) => {
  const numberOfAvailableNights = getPackagesAvailableNights(packageItem, arrival, departure);

  let isAvailable = false;
  if (packageItem && packageItem.isCabana) {
    isAvailable = areCabanasAvailable(packageItem.cabanasDetails || []);
  } else {
    isAvailable = packageItem && packageItem.amount > 0;
  }

  return {
    numberOfAvailableNights,
    shouldBeShown: isAvailable && numberOfAvailableNights > 0
  };
};

export const getPackageSelectedQuantity = (chargeType, adults, children, nights, packageQty) => {
  return chargeType === PACKAGE_CHARGE_TYPE.personPerDay ? (adults + children?.length) * nights : packageQty;
};

export function packageObjectFinder(pkgCode, packageAvailabilityList) {
  return packageAvailabilityList.find(pkg => pkg.packageCode === pkgCode);
}

export function areCabanasAvailable(cabanasList) {
  return (
    cabanasList.length > 0 &&
    cabanasList?.some(
      cabana =>
        !cabana.errorCode ||
        cabana.errorCode ===
          '' /*
      V2 related
      &&
      cabana.itemAvailable > 0 &&
      get(cabana, 'amount', get(cabana, 'roomBaseRate', 0)) > 0*/
    )
  );
}

export const filterActivitiesPackages = pkg => {
  if (optimizelyHideActivityTabs) {
    return pkg.packageTableName === 'Activities' && pkg.packageTabName === 'Attractions';
  } else if (optimizelyHideFamilyTab) {
    return (
      pkg.packageTableName === 'Activities' &&
      (pkg.packageTabName === 'Attractions' || pkg.packageTabName === 'Birthdays')
    );
  }

  return pkg.packageTableName === 'Activities';
};

export function addNewTag(packageCode, currentPackageCode) {
  if (!packageCode || !currentPackageCode) {
    return false;
  }

  return Array.isArray(packageCode)
    ? packageCode.map(p => p.trim()).includes(currentPackageCode.trim())
    : packageCode.trim() === currentPackageCode.trim();
}

export function formatPackagesFromAvailability(response) {
  const transformedResponse = [];
  Object.keys(response).forEach(key => {
    const items = response[key].items.map(item => ({
      ...item,
      ...item.content,
      packageType: [key],
      packageCode: item.productCode,
      chargeType: item.content.chargeType,
      imageAltText: item.content.imageAltText,
      packageTitle: item.content.title,
      image: item.content.image,
      bookingEngineDetails: item.content.details,
      subTitle: item.content.description,
      packageDescription: item.content.description,
      packageIcon: item.content.icon
    }));
    transformedResponse.push(...items);
  });
  return transformedResponse;
}
