import React from 'react';
import { connect } from 'react-redux';
import { closeModal, openModal } from '../../store/componentStores/GlobalModal/globalModal.actions';
import { verifyIfModalIsOpened } from '../../store/componentStores/GlobalModal/globalModal.selectors';

const withGlobalModal = (Component, key) => {
  const WrappedComponent = props => {
    return <Component {...props} />;
  };

  const mapStateToProps = state => ({
    globalModal: state.globalModal,
    isModalOpened: key ? verifyIfModalIsOpened(key, state) : undefined
  });

  const mapDispatchToProps = {
    openModal,
    closeModal
  };

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);
};

export default withGlobalModal;
