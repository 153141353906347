import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';

import { Box, Button, Flex, Text, TextLink } from '../../ui-kit';
import { FlexCard } from '../../ui-kit/Card';
import ArrowDownThinIcon from '../../ui-kit/icons/ArrowDownThin';
import theme from '../../ui-kit/themes/theme';

export const Icon = styled.img`
  width: ${({ size }) => size || '24px'} !important;
  height: ${({ size }) => size || '24px'} !important;
  align-self: ${({ alignSelf }) => alignSelf || 'center'};
`;

export const LoyaltyGenericContainer = styled(FlexCard)`
  width: ${({ isNewWidgetStyleActive }) => (isNewWidgetStyleActive ? '100%' : 'auto')};

  @media screen and (max-width: 320px) {
    padding: 0;
  }
`;

export const LoyaltyWithExpirationDateContainer = styled(FlexCard)`
  color: ${({ bgColor }) => (bgColor ? theme.colors.deepLakeBlue[100] : theme.colors.pureWhite[0])};
  width: auto;

  @media screen and (max-width: 320px) {
    padding: 0;
  }
`;

export const LoyaltyAvailableContainer = styled(FlexCard)`
  background-color: ${({ isNewWidgetStyleActive }) =>
    !isNewWidgetStyleActive ? theme.colors.deepLakeBlue[100] : theme.colors.pureWhite[0]};
  color: ${({ isNewWidgetStyleActive }) =>
    !isNewWidgetStyleActive ? theme.colors.pureWhite[0] : theme.colors.deepLakeBlue[100]};
  padding: ${({ isNewWidgetStyleActive, isMobile }) =>
    isNewWidgetStyleActive ? (isMobile ? '0px' : 'initial') : '0px'};
  ${({ isNewWidgetStyleActive }) => isNewWidgetStyleActive && `padding-top: 0px;`}
  ${({ isNewWidgetStyleActive }) => isNewWidgetStyleActive && `line-height: 16px`};
  padding-bottom: ${({ isMobile }) => (isMobile ? `15px` : `${theme.space[2]}px`)};
  border-radius: 8px;
  width: ${({ isMobile }) => (isMobile ? `100%` : 'fit-content')};
  margin: ${({ isMobile }) => (isMobile ? `initial` : 'auto')};
  margin-top: ${({ isNewWidgetStyleActive, isMobile }) => (isNewWidgetStyleActive ? 0 : isMobile ? `12px` : 'inherit')};
  align-items: center;
`;

export const LoyaltyAppliedContainer = styled(FlexCard)`
  background-color: ${theme.colors.loyaltyBackground};
  padding: 12px;
  border-radius: 8px;
  width: 100%;
  margin-top: ${theme.space[3]}px;
  color: ${theme.colors.pureWhite[0]};
  ${({ isUserAuthenticated }) => {
    if (!isUserAuthenticated)
      return css`
        cursor: pointer;
        &:hover {
          background-color: ${theme.colors.loyaltyHoverBackground};
        }
      `;
  }}
`;

export const ButtonAndTermContainer = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  ${theme.mediaQueries.md} {
    flex-direction: row;
    & > div:last-child {
      order: -1;
      justify-content: start;
    }

    & > div:first-child {
      justify-content: flex-end;
    }
  }
`;

export const TermText = styled(TextLink)`
  font-size: ${({ size }) => size || themeGet('fontSizes.0')};
  color: ${themeGet('colors.pureWhite.0')};
  text-decoration: underline !important;
  font-weight: ${({ weight }) => themeGet(`fontWeights.${weight}`) || themeGet('fontWeights.regular')};
`;

export const BookNowButton = styled(Button)`
  @media screen and (max-width: 320px) {
    padding: ${theme.space[1]}px ${theme.space[3]}px;
  }
`;

export const TextBoldWithNormal = styled(Text)`
  font-weight: bold;
  line-height: 1.25;
  & > span {
    font-weight: normal;
  }
`;

// New Styles

export const LoyaltyProfileContainer = styled(Flex)`
  min-height: 257px;
  width: 100%;
  border: 1px solid ${theme.colors.voyagersColors[100]};
  margin-bottom: ${theme.space[10]}px;
  border-radius: 16px;
  flex-direction: column;
  overflow: hidden;
  background: ${theme.colors.voyagersColors[100]};
  ${theme.mediaQueries.xl} {
    width: 587px;
  }
`;

export const PointsExpirationContainer = styled(Flex)`
  width: 100%;
  min-height: 44px;
  height: 48px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: ${theme.colors.springGreen[10]};
  border-radius: 4px;
  ${theme.mediaQueries.xl} {
    height: 44px;
  }
`;

export const SpanDot = styled.span`
  width: ${({ width }) => {
    return width ? width : 8;
  }}px;
  height: ${({ height }) => {
    return height ? height : 8;
  }}px;
  border-radius: 50%;
  background: ${theme.colors.voyagersColors[100]};
  margin-right: ${({ mr }) => {
    return mr ? mr : 4;
  }}px;
`;

export const PointsContainer = styled(Flex)`
  flex-grow: 1;
  padding: 32px;
  flex-direction: column;
  ${theme.mediaQueries.xl} {
    padding: 24px 24px 32px 24px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`;
export const PointConditionsAndTerms = styled(Flex)`
  background: ${theme.colors.springGreen[10]};
  margin-right: 4px;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  ${theme.mediaQueries.xl} {
    padding: 16px 24px;
    flex-direction: row;
    justify-content: space-between;
    & > div:last-child {
      width: 230px;
    }
  }
`;

export const BookNowWhite = styled(Box)`
  text-align: center;
  & > a {
    background-color: ${theme.colors.pureWhite[0]};
    color: ${theme.colors.voyagersColors[100]};
    &:hover {
      background-color: ${theme.colors.pureWhite[0]};
      color: ${theme.colors.voyagersColors[100]};
    }
  }
`;

// History component

export const LoyaltyProfilePointsHistoryContainer = styled(Box)`
  & > div:nth-child(odd) {
    background: ${theme.colors.snowMountainGrey[20]};
  }
  & > div:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const LoyaltyProfilePointsHistoryTableRow = styled(Flex)`
  padding: 7px 24px 9px;
  border-radius: 1px;
  justify-content: space-between;

  ${theme.mediaQueries.md} {
    border-radius: 4px;
    justify-content: initial;
  }
`;

export const LoyaltyProfilePointsHistoryTableRowJoinColum = styled(Flex)`
  width: auto;
  flex-direction: column;
  row-gap: 8px;
  ${theme.mediaQueries.md} {
    width: 269px;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 58px;
    row-gap: 0;
  }
`;

export const StyledChevronDownIcon = styled(ArrowDownThinIcon)`
  pointer-events: none;
  stroke: ${themeGet('colors.preciousStoneBlue.100')};
  stroke-width: 1;
  width: 13px;
  transform: ${({ resetAvailable }) => (resetAvailable ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const SeeMoreContainer = styled(Flex)`
  max-width: 690px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const LoyaltyProfilePointsHistoryDisclaimer = styled(Flex)`
  padding: 12px 16px;
  background: ${theme.colors.springGreen[10]};
  border-radius: 4px;
  margin-top: 24px;
  margin-bottom: 24px;
  justify-content: center;
  align-items: center;

  ${theme.mediaQueries.md} {
    padding: 16px;
    margin-bottom: 56px;
  }
`;

export const LoyaltyProfilePointsHistoryDate = styled(Text)`
  width: 95px;
`;
