// Packages Reducer

import { initializeState } from '../../../utilities/storageUtils';
import packagesActionTypes from './packages.types';

const INITIAL_STATE = {
  selectedPkgsLocationCode: '',
  selectedPackages: [],
  packageAvailabilityList: [],
  cabanasAvailabilityList: [],
  isLoading: false,
  requestFailed: false,
  errorMessage: '',
  status: '',
  isLateCheckoutDenied: false,
  isLateCheckoutOffered: false,
  planPackages: [],
  itemQuantity: []
};

const packagesReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);

  switch (action.type) {
    case packagesActionTypes.SET_PACKAGES_PLAN_INFO_SUCCESS:
      return {
        ...state,
        planPackages: action.planPackages
      };
    // Get Package Availability SUCCESS
    case packagesActionTypes.GET_PACKAGES_AVAILABILITY_INFO_SUCCESS:
      return {
        ...state,
        packageAvailabilityList: action.packageAvailabilityList,
        requestFailed: false,
        isLoading: false,
        errorMessage: ''
      };
    // Get Package Availability LOADING
    case packagesActionTypes.GET_PACKAGES_AVAILABILITY_INFO_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    // Get Package Availability FAIL
    case packagesActionTypes.GET_PACKAGES_AVAILABILITY_INFO_ERROR:
      return {
        ...state,
        packageAvailabilityList: action.packageAvailabilityList,
        requestFailed: action.requestFailed,
        isLoading: false,
        errorMessage: action.errorMessage
      };
    case packagesActionTypes.UPSERT_PACKAGES:
      return {
        ...state,
        selectedPackages: action.selectedPackages,
        selectedPkgsLocationCode: action.selectedPkgsLocationCode
      };
    case packagesActionTypes.OFFERED_PACKAGES_LCO:
      return {
        ...state,
        isLateCheckoutOffered: action.isLateCheckoutOffered
      };
    case packagesActionTypes.DECLINE_PACKAGES_LCO:
      return {
        ...state,
        isLateCheckoutDenied: action.isLateCheckoutDenied
      };
    case packagesActionTypes.DELETE_PACKAGES:
      return {
        ...state,
        selectedPackages: action.selectedPackages
      };
    case packagesActionTypes.CLEAR_PACKAGES_SELECTED:
      return {
        ...state,
        selectedPackages: action.selectedPackages,
        selectedPkgsLocationCode: action.selectedPkgsLocationCode
      };

    case packagesActionTypes.GET_PACKAGES_CABANAS_AVAILABILITY_SUCCESS:
      return {
        ...state,
        cabanasAvailabilityList: action.cabanasAvailabilityList,
        isLoading: false
      };
    // Get Package Availability LOADING
    case packagesActionTypes.GET_PACKAGES_CABANAS_AVAILABILITY_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    // Get Package Availability FAIL
    case packagesActionTypes.GET_PACKAGES_CABANAS_AVAILABILITY_ERROR:
      return {
        ...state,
        cabanasAvailabilityList: action.cabanasAvailabilityList,
        isLoading: false
      };
    case packagesActionTypes.CLEAR_PACKAGES_BY_CODES:
      return {
        ...state,
        selectedPackages: action.selectedPackages
      };
    case packagesActionTypes.CLEAR_PACKAGES_PLAN:
      return {
        ...state,
        selectedPackages: action.selectedPackages,
        planPackages: action.planPackages,
        selectedPkgsLocationCode: action.selectedPkgsLocationCode
      };
    case packagesActionTypes.GET_ADD_ONS_AVAILABILITY_INFO_SUCCESS:
      return {
        ...state,
        cabanasAvailabilityList: action.cabanasAvailabilityList,
        packageAvailabilityList: action.packageAvailabilityList,
        itemQuantity: action.itemQuantity,
        isLoading: false
      };
    // Get Package Availability LOADING
    case packagesActionTypes.GET_ADD_ONS_AVAILABILITY_INFO_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    // Get Package Availability FAIL
    case packagesActionTypes.GET_ADD_ONS_AVAILABILITY_INFO_ERROR:
      return {
        ...state,
        cabanasAvailabilityList: action.cabanasAvailabilityList,
        packageAvailabilityList: action.packageAvailabilityList,
        itemQuantity: action.itemQuantity,
        isLoading: false
      };

    default:
      return state;
  }
};

export default packagesReducer;
